.family-bundle-sidebar{
  height: -webkit-fill-available !important;
}
.fb-container {
  max-width: 400px;
  margin: 20px auto;
  height: 100%; /* Full height for flexbox to work */
  display: flex;
  flex-direction: column;
}

.fb-deal-banner {
}
.right-side-info-fb {
  display: flex;
  /* margin-bottom: 20px; */
  margin-top: 5px;
  align-items: center;
  justify-content: center;
}

.fb-price-section {
  background-color: #fefbf2;
    border: 3px dotted #ffd700;
    border-radius: 10px;
    padding: 10px;
}
.fb-price-section-inox {
  background-color: #F5F9FF;
  border: 2px dotted #234B9E;
  border-radius: 10px;
  padding: 10px;
}

.fb-original-price {
  font-size: 1.25rem;
  font-weight: 700;
  color: red !important;
}
.fb-discount{
  display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.fb-discounted-price {
  font-size: 1.25rem;
  font-weight: 700;
  color: gray !important;
}

.flex-grow-1 {
  flex-grow: 1; /* Makes the card body take all available space */
}

.fb-btn-bottom {
  position: fixed;
  background: white;
  width: -webkit-fill-available;
  bottom: 0px;
  padding: 11px;
  z-index: 10000;
}

.btn-warning-pvr {
  background-color: #ffd700;
  color: black;
  padding: 10px 33px;
  border: none;
  font-size: 12px;
  font-weight: 600;
  border-radius: 7px;
  font-family: 'Poppins';
}
.btn-warning-pvr:active {
  background-color: #ffd700 !important;
  color: black !important;
  font-weight: bold;
}
.btn-warning-inox {
  background-color: #234b9e;
  color: #fff;
  padding: 10px 33px;
  border: none;
  font-size: 12px;
  font-weight: 600;
  border-radius: 7px;
  font-family: 'Poppins';
}
.btn-warning-inox:active{
  background-color: #234b9e !important;
  color: #fff !important;
}

.btn-warning-pvr p {
  background-color: red;
}

.p-sidebar-bottom .p-sidebar {
  height: 100%;
}
.subtract {
  border-radius: 18px !important;
  padding: 0 7px !important;
  color: #000 !important;
}
.subtract svg {
  width: 10px !important;margin-top: -5px;
}
.plus {
  border-radius: 18px !important;
  padding: 0 7px !important;
}
.display-4 {
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 10px;
  text-align: -webkit-center;
  position: relative;
  top: -5px;
  left: -5px;
}
.right-side-info h2 {
  text-align: center;
}
.right-side-info {
  margin-bottom: 0px;
}
.fb-container {
  margin: auto;
}
.fb-price {
  background-image: url("../../../assets/family/ticket-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 15px;
  margin-top: 7px;
}
.fb-price-inox {
  background-image: url("../../../assets/family/ticket-bg-inox.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 15px;
  margin-top: 7px;
}
.fb-deal-banner img {
  width: 100%;
}
.plus svg {
  width: 10px;
  margin-top: -5px;
}
.bg-success {
  border-radius: 10px;
  padding: 5px 10px;
}
.faq-question {
  width: 100%;
}
.fb-ftn-left {
  float: left;
  width: 45%;
  font-family: 'Poppins';
}
.fb-ftn-right {
  float: right;
  width: 45%;
  font-family: 'Poppins';
}
.termsandcondition {
  padding-bottom: 20px;
  padding-left: 10px;
  overflow: hidden;
}
.voucher-total-left {
  float: left;
}
.voucher-total-right {
  float: right;
}
.p-sidebar-bottom .p-sidebar {
  height: 100%;
}
.faq-answer img {
  width: 140%;
  padding-right: 10px;
}
.faq-answer td {
  padding-bottom: 20px;
}
.right-side h3 {
  /* text-align: center; */
}
.terms {
  margin-bottom: 10px;
  display: flex;
}
.faq-answer-fb ul li::before {
  content: "●"; /* Use a bullet symbol */
  font-size: 1.5em; /* Adjust size as needed */
  color: currentColor; /* Matches text color */
  margin-right: 10px; /* Space between bullet and text */
}
.faq-answer-fb ul {
  list-style: none;

}

.ticket-container {
  background-color: #fff8dc; /* Light yellow background */
  border: 3px dashed #ffd700; /* Dashed yellow border */
  border-radius: 15px; /* Rounded corners */
  padding: 20px; /* Space inside the border */
  position: relative;
  width: 300px; /* Adjust width as needed */
  height: 150px; /* Adjust height as needed */
}

.ticket-container::before,
.ticket-container::after {
  content: '';
  position: absolute;
  width: 30px; /* Width of the notch */
  height: 30px; /* Height of the notch */
  background-color: #fff8dc; /* Match the background color */
  border: 3px dashed #ffd700;
  border-radius: 50%;
}

.ticket-container::before {
  top: 50%;
  left: -15px; /* Offset by half the width of the notch */
  transform: translateY(-50%);
}

.ticket-container::after {
  top: 50%;
  right: -15px; /* Offset by half the width of the notch */
  transform: translateY(-50%);
}

.hiw-steps-inox {
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%;
  margin: 0;
}

.hiw-steps-pvr {
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%;
  margin: 0;
}

.hiw-steps-inox::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 2px;
  width: 2px;
  background: repeating-linear-gradient(#234b9e,
      #234b9e 4px,
      transparent 4px,
      transparent 8px);
}

.hiw-steps-pvr::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 2px;
  width: 2px;
  background: repeating-linear-gradient(#ffcb05,
      #ffcb05 4px,
      transparent 4px,
      transparent 8px);
}

.hiw-step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative;
}

.hiw-step-icon-inox {
  width: 12px;
  height: 12px;
  background-color: #234b9e;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  top: 5px;padding-right: 12px;
}

.hiw-step-icon-pvr {
  width: 12px;
  height: 12px;
  background-color: #ffd700;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  top: 5px;
  padding-right: 12px;
}

.display-5{
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 10px;
  text-align: -webkit-center;
  position: relative;
}
.faq-question h4{font-size: 1rem;font-weight: 700;}
.faq-question span{font-size: .9rem;font-weight: 500;}

.spacing-family-bundles {
  padding-bottom: 40%;
}

.fb-voucher-details h5 {
  font-size: 18px;
  color: #000;
  font-family: 'Poppins';
}

.fb-voucher-details p {
  font-size: 14px;
}


@media (max-width: 575.98px) {
  .fb-discounted-price{
    font-size: 12px;
    font-family: 'Poppins';
  }
    .fb-original-price{
      font-size: 12px;
      font-family: 'Poppins';
    }
    .display-4{
      font-size: 12px;
      font-weight: 600;
      padding: 5px 10px;
      font-family: 'Poppins';
    }
        .faq-answer-fb ul li {
          font-size: 14px;
        }
                .fb-btn-bottom{
                  left: 0;
                }
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus{
  box-shadow: none !important;
}