/* moredropdown */
.more {
  position: relative;
  display: inline-block;
}
.more-content ul {
  display: block;
}
.more-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 240px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  height: 580px;
  overflow-y: scroll;
}

.more-content::-webkit-scrollbar {
  width: 1px;
  height: 0px;
}

.more:hover .more-content {
  display: block;
}

.more-item {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: #333;
}

.more-item:hover {
  background-color: #F1F1F1;
  width: 100%;
  border-radius: 30px;
}

.more-content ul li {
  color: #404040;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  width: 100%;
}

.menu-divider {
  background: rgba(128, 129, 144, 0.40);
  height: 1px;
  width: 100%;
}

/* moredropdown */