.add-more-sneks{
    align-items: center;
    padding: 15px 15px;
    margin-top: 10px;
}

.grab-bite {
    display: flex;
    justify-content: space-between;
}

.add-more-sneks-btn {
    padding: 3px 10px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
}

.applied-info p {
    font-size: 14px;
}
.saved-info p {
    font-size: 12px;
}
.saved-info{
    color: #1A8C2D;
}
.remove-text{
    color: #DD0000;
}
.applied-info {
    background-color: #D9ECDE;
    padding: 12px 9px;
    margin-bottom: 0px;
}
.save-remove {
    display: flex;
    justify-content: space-between;
}
.familt-cut-price {
    color: #dd0000;
    text-decoration: line-through;
    margin-right: 3px;
    font-weight: 600;
}

.ticket-cancel-allowed-pvr {
    background: #FFF0D6;
    border: 1px solid #FFCB05;
    border-radius: 6px;
    padding: 10px;
}

.ticket-cancel-allowed-pvr p {
    text-align: center;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 600;
}

.ticket-cancel-allowed-inox {
    background: #EDF3FF;
    border: 1px solid #234B9E;
    border-radius: 6px;
    padding: 10px;
}

.ticket-cancel-allowed-inox p {
    text-align: center;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 600;
}