.flexi-popup {
    width: 40% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    height: auto !important;
    margin-top: 11%;
}

.flexi-popup .p-sidebar-content{
    padding: 10px  1.25rem !important;
}

.flexi-popup-content {
    text-align: center;
}

.flexi-popup-content img {
    margin-bottom: 14px;
}
.flexi-popup-content h2 {
    font-size: 24px;
    color: #000;
    font-weight: 700;
    margin-bottom: 4px;
}
.flexi-popup-content p {
    font-size: 14px;
    margin-bottom: 14px;
    color: #000;
    text-transform: lowercase;
}
.ifyou-need {
    font-size: 14px;
    color: #6C6C6C;
    font-family: 'Poppins';
    margin-bottom: 20px;
}

.flexi-ok {
    width: 100%;
    border: none;
    padding: 9px 10px;
    border-radius: 6px;
    font-size: 14px;
    font-family: 'Poppins';
    margin-bottom: 20px;
}
.upgrade-main-box{
    border: 1px solid #BF9804;
    border-radius: 6px;
}

.upgrade-pay {
    background: #FFF0D6;
    border-radius: 6px 6px 0px 0px;
    padding: 10px;
}

.flexi-ticket-up {
    display: flex;
    align-items: center;
}

.cross-flexi-popup {
    text-align: right;
    cursor: pointer;
}

.upgrade-view-flex {
    width: 80%;
}
.flexi-ticket-price {
    width: 25%;
    text-align: right;
}
.flexi-ticket-price p {
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-bottom: 4px !important;
}
.upgrade-pay p {
    font-size: 10.8px;
    margin-bottom: 0px;
    color: #000;
}

.upgrade-view-flex p {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 4px;
}

.upgrade-tc {
    background: #BF9804;
    border-radius: 0px 0px 5px 5px;
    padding: 10px;
}
.upgrade-tc {
    background: #BF9804;
    border-radius: 0px 0px 5px 5px;
    padding: 10px;
}

.main-upgrade-terms {
    display: flex;
    align-items: center;
}

.howto-work-flexi{
    width: 44%;
}
.upgrade-flexi-up{
    width: 59%;
    text-align: right;
}

.tick-upgrade {
    margin-right: 5px;
}

.how-it-work {
    border: 1px solid #FFFFFF;
    background: transparent;
    color: #fff;
    padding: 2px 9px;
    font-size: 12px;
    border-radius: 30px;
    margin-right: 10px;
}

.tc {
    border: none;
    background: transparent;
    color: #fff;
    font-size: 12px;
    text-decoration: underline;
}

.upgrade-btn {
    font-size: 12px;
    padding: 2px 9px;
    border-radius: 30px;
    border: 1px solid #000;
    font-weight: 500;
    color: #000;
}

.upgraded-btn {
    font-size: 12px;
    padding: 4px 9px;
    border-radius: 30px;
    border: 1px solid #007D23;
    color: #007D23;
    font-weight: 500;
}

.how-itwork-icon {
    text-align: center;
}
.on-time img {
    padding-right: 7px;
    height: 23px;
}

.how-itwork-icon img {
    /* width: 50px; */
    height: 50px;
    margin-bottom: 14px;
}

.how-itwork-icon h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 14px;
}

.work-point-list ol li {
    background: #F8F6F6;
    margin-bottom: 4px;
    border-radius: 6px;
    padding: 10px;
    font-family: 'Poppins';
    color: #000;
    font-size: 14px;
}
.how-it-okay {
    width: 100%;
    border: none;
    font-family: 'Poppins';
    font-size: 14px;
    padding: 8px 10px;
    border-radius: 5px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
}
.how-it-list {
    padding: 0px 0px 0px 12px;
}

.terms-condition h2 {
    color: #000;
}

.terms-condition h5 {
    color: #000;
}

.terms-condition ol li {
    color: #000;
}

.terms-heading h4 {
    color: #000;
}


.terms-condition ul li {
    color: #000;
}

.flexi-terms-conds .p-sidebar-content{
    padding: 0px !important;
    overflow-x: hidden !important;
}
.accept-viww-flexi {
    padding: 0px 20px;
}

.terms-flexi-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.terms-heading h4 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
}
.divider-flexi {
    background: #D6D6D6;
    width: 100%;
    height: 1px;
    margin-bottom: 5px;
}
.terms-condition{
    padding: 0px !important
}
.flexi-cross {
    cursor: pointer;
    width: 10%;
    text-align: right;
}

.terms-heading{
    width: 90%;
}

.info-flexi img {
    cursor: pointer;
}

.flexi-cross-how {
    text-align: right;
    cursor: pointer;
}

.list-conditon {
    padding: 0px 27px;
}
@media (max-width: 575.98px) {
    .flexi-terms-conds .p-sidebar-content{
        padding: 0px !important;
        overflow-x: hidden !important;
    }
    .flexi-popup {
        width: 100% !important;
        margin-top: 0;
        border-radius: 12px 12px 0px 0px;
        height: auto !important;
        z-index: 99999999 !important;
    }
    .flexi-popup .p-sidebar-content {
        padding: 10px 20px !important;
    }
    .flexi-terms-cond {
        border-radius: 12px 12px 0px 0px;
        max-height: 75vh !important;
    }
    .p-sidebar.p-component.flexi-terms-cond {
        height: unset;
    }
    .flexi-terms-conds {
        border-radius: 12px 12px 0px 0px;
        height: 75vh !important;
    }
    .terms-heading h4{
        text-align: center;
    }
    .upgrade-main-box{
        margin-bottom: 10px;
    }
    .ticket-value {
        margin-bottom: 10px !important;
      }
      .terms-condition {
        padding: 0px 0px !important;
    }
    .how-it-list {
        padding: 0px 0px 0px 0px;
    }
    .how-to-w {
        padding: 0px 0px 0px 13px;
    }
}