.now-showing-filter-inox .p-tabview .p-tabview-nav {
    border-bottom: 2px solid #F1F1F1;
}

.now-showing-filter-pvr .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border-bottom: 2px solid #F1F1F1;
}

.movie-action-bread ul li {
    float: left;
    list-style: none;
    display: flex;
    align-items: center;
    margin-right: 4px;
    font-size: 12px;
}

.all-home-lang {
    margin-left: 8px;
}

.movie-action-bread {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.languages-now {
    font-size: 10.5px;
}

.lang-movie-type p {
    font-size: 10.3px;
    margin: 0;
}

.n-dots {
    width: 4px;
    height: 4px;
    display: block;
    background: #808190;
    margin-right: 3px;
    margin-left: 3px;
}

.p-carousel-indicators {
    display: none;
}

.formats-prev-dynamic {
    display: block;
}

.mobile-new {
    display: none;
}
.nowshowing-poster-show .p-card-header img {
    width: 100%;
    height: 100%;
    /* height: 309px; */
    object-fit: cover;
}

.experiences-box {
    display: -webkit-inline-box;
    overflow: scroll;
    padding: 0px;
  }

  .experiences-box::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

.formate-btn-hide-box {
    background: #fff;
    border: 1px solid #E8E8E8;
    box-shadow: 0px 1px 0px #E8E8E8;
    border-radius: 7px;
    padding: 6px 22px 6px 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
}

.formate-btn-hide-box img {
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.second-fromat-view {
    display: flex !important;
}   

.nowshowing-poster-show .p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover{
    /* border: none !important; */
    border: 1px solid #D6D6D6;
}

.Book-details {
    display: flex;
    align-items: center;
}

.i-details-inox i {
    margin-left: 10px;
    background: #fff;
    border: 1px solid #D6D6D6;
    padding: 9px 9px;
    border-radius: 4px;
    font-size: 17px;
    color: #234b9e;
}
.i-details-pvr i {
    margin-left: 10px;
    background: #fff;
    border: 1px solid #D6D6D6;
    padding: 9px 9px;
    border-radius: 4px;
    font-size: 17px;
    /* color: #FFCB05; */
    color: #000;
}

.i-details-pvr i:hover {
    background: #FFCB05;
    color: #000;
}
.i-details-inox i:hover {
    background: #234b9e;
    color: #fff;
}
.imax-logo-show-poster {
    position: absolute;
    top: 20px;
}

.imax-logo-show-poster img {
    width: 30% !important;
    background: #000;
    padding: 4px 4px;
    border-radius: 0px !important;
}

.imax-logo-show-poster-without-rt {
    position: absolute;
    top: -6px;
}

.imax-logo-show-poster-without-rt img {
    width: 30% !important;
    background: #000;
    padding: 4px 4px;
    border-radius: 0px !important;
}

.mobile-home-placeholder {
    display: flex !important;
    width: 100vw;
    overflow-x: scroll !important;
    overflow-y: hidden;
    height: 13vh;
}

.mobile-placeholder img {
    width: 92vw;
    padding: 5px 6px;
    margin-left: 15px;
    border-radius: 15px;
}
.gnr-icon{
    margin-right: .25rem !important;
    height: 12px;
}


