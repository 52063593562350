.payment-mobile-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    position: fixed;
    background: #fff;
    width: 100%;
    top: -2px;
    z-index: 999;
}
.payment-header-item{
 display: flex;
 align-items: center;
}
.payment-header-item h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 5px 0 3px 23px;
  }

  .mobile-pay-button-box{
    position: fixed;
    background: #fff;
    bottom: 0;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.10);
    padding: 13px 20px 0px 20px;
  }
  .Check-out-cancel-ticket h5 {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 15px;
    padding-top: 20px;
    border-bottom: 1px solid #EDE8E9;
}

  .mobile-payment-spaces {
    /* margin: 15px; */
    padding: 10px 15px;
}

  .mobile-payment-method h6 {
    font-size: 12px;
    color: #7a7a7a;
    margin-bottom: 10.5px;
    font-weight: 500;
    text-transform: uppercase;
}

.mobile-payment-method ul li {
    list-style: none;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    margin-bottom: 12px;
    padding: 6.25px 6.25px;
    border-bottom: 1px solid #ccc;
}
.cricle-session {
  width: 25px;
  height: 25px;
  background: #000;
  border-radius: 50%;
}

.cricle-session .pi.pi-times {
  color: #ffffff !important;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.mobile-payment-alert-dialog {
    text-align: center;
    /* padding: 12px 0 24px 0; */
}
.endthe-session {
  padding: 14px 6px;
  font-size: 14px;
  font-family: poppins;
}
.mobile-payment-alert-dialog .checked-applied p {
  margin-top: 18px;
}

.proceed-code p {
  text-align: center;
  background: #FFF0D6;
  margin: 0;
  padding: 12px 0px;
  border-radius: 8px 8px 0px 0px;
  font-size: 14px;
  margin-bottom: -6px;
  font-weight: 600;
}
.payto-check {
  font-weight: 600;
  margin-bottom: 0px;
}
.Check-out-cancel-ticket {
    border: 1px solid #FFCB05;
    margin: 15px 20px;
    text-align: center;
    border-radius: 8px;
    position: relative;
    margin-bottom: 43%;
}

.Check-out-cancel-ticket h5 {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 10px;
    padding-top: 20px;
    border-bottom: 1px solid #EDE8E9;
}

.Check-out-refund {
  padding: 14px 0px 1px 0px;
}
.Check-out-refund h6 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.Check-out-refund p {
  margin-bottom: 6px !important;
  width: 76%;
  margin: 0 auto;
}
.Check-out-refund p:last-child {
  margin-bottom: 18px !important;
}
.ticket-line-check {
  width: 69%;
  background: #EDE8E9;
  height: 1px;
  margin: 0 auto;
}

.ticketup {
  position: absolute;
  top: -22px;
  left: 45%;
}

.ticketup img {
  background: #fff;
}

.mobile-payment-user {
  padding: 0px 20px 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-grand-amount-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.check-payment-pvr .p-checkbox .p-checkbox-box.p-highlight{
  background-color: #FFCB05;
  border-color:#FFCB05;
}
.check-payment-pvr .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #FFCB05;
  background-color: #FFCB05;
}
.check-payment-pvr .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
  box-shadow: none;
  border-color:#FFCB05;
}

.check-payment-inox .p-checkbox .p-checkbox-box.p-highlight{
  background-color: #234b9e;
  border-color:#234b9e;
}
.check-payment-inox .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #234b9e;
  background-color: #234b9e;
}
.check-payment-inox .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
  box-shadow: none;
  border-color:#234b9e;
}
.set-view-fift {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Total-amount-gift h6 {
  margin: 0;
}
.pro-gift .btn-proceeded {
  margin-bottom: 14px;
}
.Mobile-summary-gift {
  margin-bottom: 60%;
  overflow: scroll;
}
.terms-gift-connect {
  position: fixed;
  bottom: 74px;
  padding: 7px 18px;
  width: 100%;
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  background: #fff;
}

.terms-gift-connect p {
  margin-bottom: 0px;
  margin-top: 3px;
  margin-left: 11px;
}

.check-box-gift-pvr {
  margin-right: 10px;
}
.check-box-gift-pvr .p-checkbox .p-checkbox-box.p-highlight{
  border-color: #FFCB05;
  background: #FFCB05;
}

.check-box-gift-inox .p-checkbox .p-checkbox-box.p-highlight{
  border-color: #234b9e;
  background: #234b9e;
}
.check-box-gift-pvr .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover{
  border-color: #FFCB05;
  background: #FFCB05;
}
.check-box-gift-inox .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover{
  border-color: #234b9e;
  background: #234b9e;
}
.check-box-gift-pvr .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
  border-color: #FFCB05;
  box-shadow: none;
}
.check-box-gift-inox .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
  border-color: #234b9e;
  box-shadow: none;
}
.terms-check-box {
  display: flex;
}

.secure-payment {
  text-align: center;
  padding: 18px 0px;
}

.pay-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.panacea {
  margin-right: 18px;
}

.secure-payment p {
  font-size: 14px;
  font-weight: 500;
  color: #838383;
}

.panacea img {
  width: 100%;
  height: 42px;
}

.voucher-passport-new {
  background: #6F1612;
  padding: 20px 20px 20px 20px;
  border-radius: 6px 6px 0px 0px;
}
.phone-view-passport {
  display: flex;
  overflow-x: scroll;
}
.passport-blur {
  filter: blur(2px);
}
.passport-card-number h6 {
  color: #B78A88;
  font-size: 14px;
  margin-bottom: 4px;
}
.Mobile-passport {
  text-align: center;
}
.passport-voucher {
  align-items: center;
  border: 1px solid #DDD;
    border-radius: 0px 0px 6px 6px;
    padding: 5px 3%;
}
.voucher-redmeeauto {
  margin-right: 10px;
  width: 90%;
  flex-shrink: 0;
  margin-bottom: 20px;
}
.unlock-passport p {
  font-size: 12px !important;
  margin-bottom: 0;
  font-weight: 600;
  color: #000;
  display: inline-block;
  padding: 5px;
}
.redmee-tick-mobile {
  display: flex;
  align-items: center;
}
.passport-redmee p {
  font-size: 9.6px !important;
  margin-bottom: 0px;
  margin-left: 5px;
  margin-top: -5px;
  font-weight: 600;
}

.passport-card-number h4 {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
}

@media (max-width: 575.98px){
  .do-want p{
    margin-top: 15px;
    padding: 0 15px;
  }
.show-in-desktop-view {
    display: none !important;}

    .d-flex.justify-content-between.do-you-want {
      /* background-color: #ffcb05; */
      padding: 8px 35px;
      border-radius: 0px 0px 6px 6px;
  }
  .d-flex.justify-content-between.do-you-want button.sc-crrtmM.jcxIPY {
    border: navajowhite;
    font-weight: bold;
    font-family: 'Poppins';
}
.end-the-session {
  color: #000;
  /* background-color: #FFCB05; */
  width: 100%;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px auto;
  border: none;
}
.btn-session-end {
  border: none;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  font-family: 'Poppins';
}
.explore-menu {
  width: 100%;
}
.btn-explore-menu {
  border: none;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  font-family: 'Poppins';
}
.end-the-session {
  padding: 21px 13px;
}
.do-you-want-to {
  margin: 13px 0px 13px 0px;
  font-family: 'Poppins';
}
.end-the-session-box {
  width: 100%;
}
.menu-crosser {
  position: absolute;
  right: 15px;
  top: 10px;
}
.no-m {
  border: none;
  padding: 0px 18px;
  font-family: poppins;
}

}

/* new bank offer design  */
.bank-offer-new {
  margin-bottom: 14px;
}
.bank-offer-new  .now-showing-filter-inox .p-tabview .p-tabview-nav{
  border: 1px solid #dee2e6;
}
.all-bank-list-show {
  display: flex;
  flex: 0 auto;
  overflow-x: scroll;
  margin-bottom: 20px;
  margin-top: 17px;
  /* padding: 0px 14px; */
}
.all-bank-list-show::-webkit-scrollbar {
  height: 3px;
}

.all-bank-list-show::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
.all-bank-list-show::-webkit-scrollbar-thumb {
  background: #888; 
}
.Bank-name-inox{
  border: 1px solid #234B9E;
  background: #DCE2EF;
  border-radius: 30px;
  color: #7A7A7A;
  margin-right: 8px;
  cursor: pointer;
  padding: 4px 17px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  white-space: nowrap;

}

.Bank-name-pvr {
  border: 1px solid #FFCB05;
  background: #FFF0D6;
  border-radius: 30px;
  color: #7A7A7A;
  margin-right: 8px;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 4px 17px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  white-space: nowrap;
}

.Bank-name-pvr p {
  font-size: 13px;
  margin: 0;
  font-weight: 500;
  color: #000;
  font-weight: 500;
  cursor: pointer;
}

.Bank-name-inox p {
  font-size: 13px;
  margin: 0;
  font-weight: 500;
  color: #000;
  font-weight: 500;
  cursor: pointer;
}

.Bank-name {
  border: 1px solid #EDE8E9;
  background: #fff;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7A7A7A;
  margin-right: 8px;
  padding: 4px 17px;
  height: 30px;
  flex : 0 0 auto;
  cursor: pointer;
  margin-bottom: 10px;
  white-space: nowrap;

}

.Bank-name p {
  font-size: 13px;
  margin: 0;
  font-weight: 500;
  cursor: pointer;
}
.explore-offer-new {
  padding: 5px 16px;
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 600;
  border: none;
  border-radius: 5px;
}
.Apply-offer-new {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* New Bank Offer design */
.format-passport-view {
  display: flex;
  justify-content: space-between;
  background: #dce2ef;
  align-items: center;
  padding: 8px 6px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.fromat-popup-view h3 {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0;
}

.fromat-popup-price p {
  font-weight: 600;
  color: #000;
  font-size: 14px;
  margin-bottom: 0;
}

.please-review-pvr {
  padding: 8px 6px;
  margin-bottom: 10px;
  border-radius: 6px;
}
.upgrade-your h3 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.please-review-pvr p {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0px ;
}

.surcharge-proceed {
  border: none;
  margin-right: 10px;
  padding: 10px 30px !important;
  font-family: 'Poppins';
  font-size: 13px;
  border-radius: 5px;
  font-weight: 600;
}
.surcharge-proceed:last-child{
  margin-right: 0px;
}
.discount-price {
  color: #1A8C2D;
  font-weight: 700;
  text-decoration: line-through;
  margin-right: 10px;
}
.payment-privilege {
  padding: 24px 18px;
  background: #fff;
  border-radius: 0px 0px 8px 8px;
}
.privilege-head h4 {
  font-size: 15px;
  color: #808190;
  margin-bottom: 20px;
}
.privilege-voucher-show-details {
  background: #000;
  padding: 10px;
  border-radius: 6px 6px 0px 0px;
}

.vail-date-privi {
  display: flex;
  align-items: flex-end;
}

.privilege-qr {
  width: 25%;
  padding-right: 8px;
}
.privilege-id {
  width: 75%;
}
.privilege-id h6 {
  font-size: 12px;
  color: #fff;
  margin-bottom: 3px;
}

.privilege-id p {
  font-size: 9px;
  color: #fff;
  margin-bottom: 0px;
}
.privilege-qr h5 {
  margin-bottom: 4px;
  font-size: 14px;
  color: #fff;
}
@media (max-width: 575.98px) {
  .verfiy-paytm {
    width: 88% !important;
}
.privilege-voucher {
  margin-bottom: 15px;
}
.privilege-qr {
  width: 20%;
}
.privilege-id h6 {
  font-size: 20px;
}
.privilege-id p {
  font-size: 16px;
}
.privilege-qr h5 {
  font-size: 16px;
}
.privilege-head {
  padding: 0px 20px;
}
.passport-voucher {
  display: flex;
}
.bank-offer-box {
  padding: 10px 20px;
}
}
