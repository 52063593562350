.p-overlaypanel {
    width: 100% !important;
}

.p-overlaypanel .p-overlaypanel-content {
    width: 84%;
    margin: 0 auto;
}

.search-dropdown .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.search-dropdown .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    font-size: 14px;
    font-weight: 600;
}

.search-dropdown .p-tabview .p-tabview-nav {
    border: none;
}

.search-dropdown .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border-bottom: none !important;
}

.search-dropdown .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.search-dropdown .p-tabview-nav-container {
    border-bottom: 1px solid #DDD;
    padding-bottom: 16px;
}

.search-dropdown .p-tabview .p-tabview-panels {
    padding: 32px 0px;
}

.search-all-movies {
    display: flex;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.search-movies-name img {
    height: 79px;
    overflow: hidden;
    padding-right: 10px;
    /* width: 100%; */
}

.movies-search-details h6 {
    font-size: 14px;
    font-family: 'Poppins';
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
    width: max-content;
}

.search-new-inox {
    color: #234B9E;
    font-size: 11px;
    margin-left: 8px;
}

.search-new-pvr {
    color: #FFCB05;
    font-size: 11px;
    margin-left: 8px;
}

.movies-search-details ul li {
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    margin-right: 6px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    color: #808190;
    font-size: 12px;
}

.search-views ul li {
    font-size: 12px;
    border: 1px solid #D6D6D6;
    padding: 2px 4px;
    border-radius: 2px;
}

.search-movies-cinemas {
    width: 30%;
}

.movies-search-details {
    display: grid;
    width: 70%;
}

.movies-search-details p {
    color: #808190;
    font-size: 12px;
    margin-bottom: 10px;
}

.may-cinemas {
    display: flex;
    align-items: center;
}

.direction-search p {
    margin-bottom: 20px;
    margin-right: 20px;
}

.search-icon {
    margin-right: 4px;
}

.search-city h3 {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    margin-bottom: 24px;
    text-transform: capitalize;
}

.all-show-city {
    margin-bottom: 40px;
}

.search-viewed {
    margin-top: 20px;
}

.search-formats {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.all-formats-search {
    position: absolute;
    top: 16px;
    z-index: 999;
    right: 9.8%;
}

.search-dropdown .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 15px 15px 10px 15px;
}

.search-dropdown .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    /* background: #234B9E; */
    margin: 0 auto;
}

/* .search-movies-name img {
    width: 120px;
    height: 67px;
    overflow: hidden;
} */
.mobile-search-cinemas-show .search-movies-name img {
    width: 100% !important;
    height: 67px;
}

.p-overlaypanel .p-overlaypanel-close {
    background-color: #fff;
    color: #000;
    border: 1px solid gray;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.p-overlaypanel .p-overlaypanel-close:hover {
    background-color: #fff !important;
    color: #000 !important;
    border: 1px solid gray !important;
}

.search-movies-cinemas img {
    width: 100%;
    padding-right: 10px;
    height: 90px;
}

.overlay-panel {
    position: fixed;
    z-index: 1000;
    /* Adjust the z-index as needed */
}

/* new styling */
.search-bx {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.search-content::-webkit-scrollbar {
    width: 10px;
  }
  
.search-content::-webkit-scrollbar-track {
    background: rgb(212, 212, 212); 
  }

  .search-content::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  .search-content::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
   

.search-content {
    /* display: none; */
    position: fixed;
    top: 3rem;
    left: 2px;
    right: 2px;
    bottom: 5rem;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 9999;
    padding: 10px;
    overflow-y: scroll;
    width:100%;

}

.search-bx:hover .search-content {
    display: block;
}
.search-dropdown {
    width: 84%;
    margin: 0 auto;
    position: relative;
}
.search-content .now-showing-filter-inox .p-tabview .p-tabview-nav {
    border-bottom: none;
}
.search-colse-btn {
    position: absolute;
    top: 14px;
    right: 25px;
    background-color: #fff;
    border: 1px solid gray;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gnr-icon{
    margin-right: .25rem !important;
    height: 12px;
  }