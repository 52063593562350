.active-languages-inox{
    background: #234b9e !important;
    color: #fff !important;
}

.active-languages-pvr{
    background: #FFCB05 !important;
    color: #000 !important;
}

.home-icon-pvr-active {
    background: #FFF0D6;
    border: 1px solid #FFCB05;
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 4px;
}
.home-icon-inox-active {
    background: #D6E3FF;
    border: 1px solid #234C9E;
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 10px;
}

/* .filter-box {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 290px;
} */