
.b-bread-movies {
    margin-bottom: 24px;
}
.b-arrow-backs {
    margin-right: 10px;
}
.b-bread-inox-active {
    color: #fff !important;
    border-bottom: 1px solid #fff;
}
.b-bread-pvr-active {
    color: #fff !important;
    border-bottom: 1px solid #FFCB05;
}
.b-back{
    color: #fff !important;
}

.b-bread-movies ul {
    display: flex;
    align-items: center;
}

.b-bread-movies ul li:not(:first-child , :last-child)::after {
    content: url('../../../assets/moviesession/arrow_right.svg');
    position: absolute;
    margin-left: 5px;
}
.b-bread-movies ul li {
    list-style: none;
    margin-right: 40px;
    color: #fff;
    font-size: 12px;
}

/* === seat layout style ========*/

.seat-arrow-backs {
    margin-right: 10px;
}
.seat-bread-active {
    /* color: #fff !important; */
    /* text-decoration: underline; */
    border-bottom: 1px solid #000;
}

.seat-back{
    color: #000 ;
}

.seat-bread-movies ul {
    display: flex;
    align-items: center;
    padding: 14px;
}

.seat-bread-movies ul li:not(:first-child , :last-child)::after {
    content: url('../../../assets/seatlayout/arrow_right.svg');
    position: absolute;
    margin-left: 5px;
    margin-top: -2px;

}
.seat-bread-movies ul li {
    list-style: none;
    margin-left: 40px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 500;
}
.food-bread-mobile{
    display: block;
}

.seat-bread-movies ul li:first-child {
    margin-left: 0px;
}