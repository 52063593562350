.bg-seatlayout {
  background-color: #f1f1f1;
}

.arrow-backs {
  margin-right: 10px;
}

.seat-active {
  color: #000000 !important;
  text-decoration: underline;
}

.prev {
  color: #000 !important;
}

.backs {
  color: #000 !important;
  margin-right: 40px !important;
}

.seat-bread ul {
  display: flex;
  align-items: center;
}

.seat-bread ul li {
  float: left;
  list-style: none;
  font-weight: 500;
  font-size: 10px;
  margin-right: 60px;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  cursor: pointer;
}

.seat-bread ul li:not(:first-child, :last-child)::after {
  content: url("../../assets/seatlayout/arrow_right.svg");
  position: absolute;
  margin-left: 22px;
}

.seat-bread {
  background: #dce2ef;
  padding: 14px 14px;
}

.seat-views {
  padding-left: 0px;
  background: #fff;
  padding-right: 0px;
}

.book-summary {
  padding-right: 0px;
  background: #F1F1F1;
}

.date-time {
  display: flex;
  align-items: center;
}

.seatlayout-time-wayed {
  padding-bottom: 2px;
}

.select-time .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

.select-time .p-tabview .p-tabview-nav .p-tabview-ink-bar {
  /* background-color: #234b9e; */
}

.select-time .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #000;
  border-color: #234b9e;
  font-size: 12px;
}
.date-time .p-tabview .p-tabview-nav li{
  font-size: 12px;
  display: flex;
}

.select-time {
  width: 83%;
}

.select-date {
  width: 18%;
  border-bottom: 2.2px solid #F1F1F1;
  border-right: 2.1px solid #F1F1F1;
  /* padding: 5px 5px; */
}
.three-view {
  height: 9px;
}
.select-date.align-self-center.desktop-seat-bread {
  height: 57px;
}

.date-time svg {
  color: #000;
  position: relative;
  left: 0px;
  font-size: 0px !important;
  width: 13px;
  height: 16px;
  top: 0px;
}

@media screen and(max-width: 575px) {
  
  }

.small.seatlayout-time-wayed {
  padding: 3px 0px;
  font-size: 11px;
}
.seatlayout-time-wayed b {
  font-weight: 700 !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
  padding: 7px 7px;
  margin: 5px 10px 5px 10px;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #000;
  background: #f1f1f1;
  border-radius: 30px;
  padding: 7px 7px;
  font-size: 12px;
  margin: 5px 10px 5px 10px;
  font-weight: 400;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #000;
  background: #f1f1f1;
  border-radius: 30px;
  padding: 7px 7px;
  margin: 5px 10px 5px 10px;
  font-size: 12px;
  font-weight: 400;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
  font-size: 12px;

}

.select-date .p-dropdown .p-dropdown-label {
  font-family: "Poppins";
  font-weight: 700;
  color: #000;
  font-size: 12px;
  margin-top: 6px;
}

.select-date .p-dropdown .p-dropdown-label.p-placeholder {
  font-size: 12px !important;
  font-weight: 700 !important;
  font-family: "Poppins";
  color: #000 !important;
}

.select-date .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

.select-date .p-dropdown {
  border: none;
  border-radius: none !important;
  background: none;
}

.select-time .p-tabview .p-tabview-panels {
  padding: 0px;
}

.select-time .p-tabview .p-tabview-nav {
  border-bottom: 2px solid #ddd;
}

.booking-summary {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 8.25px 9px 8.25px 12px;
}

.all-summary {
  border-left: 1px solid #ddd;
  height: 100%;
  background: #fff;
}

.book-head h3 {
  font-size: 12.5px;
  font-weight:700;
  margin: 0px;
}
.timer-body{
  font-size: 14px;
  font-weight: 500;
}
.summary-movies-content ul li {
  color: #000;
  float: left;
  list-style: none;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-bottom: 3px;
}

.summary-movies-content h5 {
  font-size: 12px;
  color: black;
  font-weight: 700;
  margin-bottom:5px;
}

.summary-movies-content p {
  font-size: 12px;
  margin: 0;
  margin-bottom: 3px;
}

.summary-movies-content {
  display: inline-block;
}

.movies-summary {
  display: flex;
  padding: 11px 9px;
  border-bottom: 1px solid #dddd;
  background: #fff;
}

.summary-movies-content {
  display: grid;
  width: 78%;
}

.summary-movies-photo {
  margin-right: 14px;
  width: 22%;
}

.dot-c {
  height: 4px;
  width: 4px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}

.no-seat-select img {
  margin-bottom: 14px;
}

.no-seat-select h5 {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-bottom: 0px;
}

.no-seat-select p {
  font-size: 12px;
  font-weight: 600;
  color: #7a7a7a;
  margin-bottom: 0;
}

.select-seat {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background: white;
}
.summary-movies-photo img {
  height: 89px;
  overflow: hidden;
  width: 60px;
  object-fit: cover;
}
.no-seat-select {
  text-align: center;
}

/* main all seat */
.expand-icon {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  align-items: center;
  padding: 0px 20px;
}

.expand-icon img {
  background: #fff;
  border: 1px solid #d6d6d6;
  padding: 8px;
  border-radius: 5px;
}

.all-seats table {
  width: 100%;
}
/* .all-seats::-webkit-scrollbar {
  width: 5px !important;
  height: 0px;
} */
.screen {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seats-info {
  text-align: center;
  max-width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
}

.available-box {
  border: 1px solid #7a7a7a;
  background-color: #fff;
  border-radius: 2px;
  width: 12px;
  height: 12px;
  margin-right: 11px;
}

.unavailable-box-inox {
  background-color: #234b9e;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-left: 15px;
  margin-right: 15px;
}

.unavailable-box-pvr {
  background-color: #FFCB05;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-left: 15px;
  margin-right: 15px;
}

.disable-person-box {
  background-color: #8205ff;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-left: 15px;
  margin-right: 4px;
}

.occupied-box {
  background-color: #e5e5e5;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-left: 15px;
  margin-right: 15px;
}

.screen-viewa p {
  text-align: center;
  margin-bottom: 15px;
  font-size: 10px;
  font-weight: 600;

}

.screen-viewa {
  margin-top: -43px;
}

.available-text h6 {
  color: #808190;
  font-size: 11px;
  margin: 0;
  font-weight: 400;
  margin: 3px;
  font-family: 'Poppins';
}

.dialog-screen {
  margin-top: 30px;
}

.seat-current-inox {
  width: 14px;
  height: 14px;
  border: 1px solid #7a7a7a;
  border-radius: 4px;
  /* margin-bottom: 14px; */
  font-size: 8px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11.5px;
  color: #000;
  cursor: pointer;
  transition: transform 0.2s ease;
  margin: 2px;
  font-family: 'Poppins';
}

.seat-current-inox:hover {
  background-color: #234b9e;
  border: 1px solid #234b9e;
  color: #fff;
  cursor: pointer;
  transform: translateY(-5px);
}

.seat-current-pvr {
  width: 14px;
  height: 14px;
  border: 1px solid #7a7a7a;
  border-radius: 4px;
  /* margin-bottom: 14px; */
  font-size: 8px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11.5px;
  color: #000;
  cursor: pointer;
  transition: transform 0.2s ease;
  /* margin-right: 14px; */
  margin: 2px;
  font-family: 'Poppins';
}

.seat-current-pvr:hover {
  background-color: #FFCB05;
  border: 1px solid #FFCB05;
  cursor: pointer;
  transform: translateY(-5px);
}

.seat-selected-inox {
  width: 14px;
  height: 14px;
  background-color: #234b9e;
  border: 1px solid #234b9e;
  border-radius: 4px;
  /* margin-bottom: 14px; */
  font-size: 8px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11.5px;
  color: #fff;
  cursor: pointer;
  transition: transform 0.2s ease;
  /* margin-right: 14px; */
  margin: 2px;
  font-family: 'Poppins';
}

.seat-selected-inox:hover {
  background-color: #234b9e;
  border: 1px solid #234b9e;
  color: #fff;
  cursor: pointer;
  transform: translateY(-5px);
}

.seat-selected-pvr {
  width: 14px;
  height: 14px;
  background-color: #FFCB05;
  border: 1px solid #FFCB05;
  border-radius: 4px;
  /* margin-bottom: 14px; */
  font-size: 8px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11.5px;
  cursor: pointer;
  transition: transform 0.2s ease;
  /* margin-right: 14px; */
  margin: 2px;
  font-family: 'Poppins';
}

.seat-selected-pvr:hover {
  background-color: #FFCB05;
  border: 1px solid #FFCB05;
  cursor: pointer;
  transform: translateY(-5px);
}

.seat-selected-disabled-person {
  width: 14px;
  height: 14px;
  background-color: #8205FF;
  border: 1px solid #8205FF;
  border-radius: 4px;
  /* margin-bottom: 14px; */
  font-size: 8px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11.5px;
  color: #fff;
  cursor: pointer;
  transition: transform 0.2s ease;
  /* margin-right: 14px; */
  margin: 2px;
  font-family: 'Poppins';
}

.seat-selected-disabled-person:hover {
  background-color: #8205FF;
  border: 1px solid #8205FF;
  color: #fff;
  cursor: pointer;
  transform: translateY(-5px);
}

.seat-current-dperson {
  width: 14px;
  height: 14px;
  border: 1px solid #8205FF;
  border-radius: 4px;
  /* margin-bottom: 14px; */
  font-size: 8px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11.5px;
  color: #8205FF;
  cursor: pointer;
  transition: transform 0.2s ease;
  /* margin-right: 14px; */
  margin: 2px;
  font-family: 'Poppins';
}

.seat-current-dperson:hover {
  background-color: #8205FF;
  border: 1px solid #8205FF;
  color: #fff;
  cursor: pointer;
  transform: translateY(-5px);
}

.seats-col-type{
  height: 14px;
  margin: 14px 0;
}

.seat-disable {
  width: 14px;
  height: 14px;
  background-color: #e5e5e5;
  border-radius: 4px;
  /* margin-bottom: 14px; */
  font-size: 8px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11.5px;
  color: #7a7a7a;
  /* margin-right: 14px; */
  margin: 2px;
  border: 1px solid #e5e5e5;
  font-family: 'Poppins';
}

.seat_hidden{
  width: 14px;
  height: 14px;
  background-color: #e5e5e5;
  border-radius: 4px;
  /* margin-bottom: 14px; */
  font-size: 8px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11.5px;
  color: #7a7a7a;
  /* margin-right: 14px; */
  margin: 2px;
  visibility: hidden;
  /* visibility: visible; */
  font-family: 'Poppins';
  border: 1px solid transparent;
}

.seat-disabled-person {
  width: 14px;
  height: 14px;
  background-color: #8205FF;
  border-radius: 4px;
  /* margin-bottom: 14px; */
  margin: 2px;
  font-size: 8px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11.5px;
  color: white;
  font-family: 'Poppins';
}

.all-seats table {
  text-align: center;
}

.seats_area_type {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Poppins';
  
}

.seats_area_type {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.seat-info h6 {
  font-size: 11px;
  font-weight: 500;
  color: #7a7a7a;
  margin-bottom: 7.5px;
  text-transform: uppercase;
}

.seat-info p {
  font-size: 11.5px;
  font-weight: 500;
  margin-bottom: 10.5px;
}

.select-seat-number {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.seat-number {
  padding: 8px 11px;
  background: #e0e6f1;
  display: flex;
  align-items: center;
  margin-right: 8px;
  border-radius: 5px;
  margin-bottom: 8px;
}

.seat-number p {
  margin: 0;
}

.seat-info {
  padding: 11px 9px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  margin-bottom: 10px;
  background: #fff;
}

.seat-number p {
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  color: #000;
}

.ticket-prices {
  /* padding: 11px 9px; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
  margin: 10px 0px;
  background: #fff;
}

.ticket-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  /* padding: 11px 9px; */
}

.tickets-count h6 {
  margin-bottom: 0px !important;
  padding: 0px !important;
}


.ticket-value-ticket {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 9px 0px 11px;
  margin-bottom: 10px;
}

.ticket-prices h6 {
  font-size: 12px;
  font-weight: 500;
  color: #7a7a7a;
  margin-bottom: 0px;
  text-transform: uppercase;
  padding: 5px 6px 7px 9px;
}

.tickets-count h6 {
  font-size: 12px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0px;
}

.ticket-price p {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}


.bill-details {
  padding: 11px 9px;
  box-shadow: rgba(0, 0, 0, 0.1) 2px -1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background: #fff;
  border-bottom: 1px solid #CCC;
}

/* .food-pay-amount{
  height: 140px;
} */

.food-pay-amount::-webkit-scrollbar {
  width: 10px;
}

.bill-amount {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.bill-details h6 {
  font-size: 12px;
  font-weight: 500;
  color: #7a7a7a;
  margin-bottom: 10px;
  text-transform: uppercase;
  padding: 0px ;
  display: flex;
    align-items: center;
}

.sub-total h6 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
  color: #000;
  text-transform: capitalize;
}

.tax-fees h6 {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: #000;
}
.ticket-cancel-cross {
  top: 23px !important;
  right: 26px !important;
}
.seatlayout-date-wayed {
  font-size: 10px;
  font-weight: 400;
  padding-bottom: 2px;
}
.btn-proceeded {
  border: none;
  padding: 12px 0px;
  /* background: #234B9E; */
  /* color: #fff; */
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  margin-bottom: 0;
  width: 100%
}

.grand-amount {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px 2px 0px 4px;
}

.grand-total h6 {
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

.grand-prices h6 {
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

.seat_hide {
  pointer-events: none;
  visibility: hidden !important;
  width: 24px;
  height: 24px;
}

.seats-row.seats {
  display: flex;
  justify-content: center;
}

.seats-cinemas {
  display: flex;
  flex-direction: column;
}

.all-seats {
  padding: 0px 20px;
  text-align: center !important;
  /* overflow-x: scroll; */
  width: 100%;
  display: grid;
  margin-bottom: 33px;
}
.all-seats::-webkit-scrollbar-thumb {
  background: #888;
  width: 10px !important;
}
.seats-row tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  place-self: center;
}
.biil-taxes-line {
  margin: 0 auto;
  width: 100%;
  height: 1px;
  background: #CCC;
  margin: 20px 0px;
}

.all-grand {
  background: #fff;
  padding: 11px 9px;
  padding: 20px 9px 10px 9px;
  box-shadow: 0px -1px 0px #DDD;
}

.register-btn {
  text-align: center;
}

.ticket-cancel img {
  cursor: pointer;
}

.cross-icon {
  position: absolute;
  right: 35px;
  top: 16px;
}

.cross-icon .pi.pi-times {
  color: #000 !important;
  font-size: 17px;
  cursor: pointer;
}

.p-dialog-visible {
  display: flex;
  background: rgba(0, 0, 0, 0.70);
  backdrop-filter: blur(2px);
}

.ticke-cancel-modal {
  background: #ffff;
  border-radius: 10px;
}

.ticket-terms {
  padding-top: 5px;
}

.ticket-terms h1 {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
}

.refund-food {
  background: #F8F8F8;
  padding: 22px 20px;
  margin-bottom: 8px;
  border: 1px solid #ECECEC;
  border-radius: 8px;
}

.refund-food h4 {
  font-size: 14px;
  margin: 0;
  font-weight: 600;
  color: #000;
}

.refund-movies {
  padding: 22px 20px;
  background: #F8F8F8;
  border: 1px solid #ECECEC;
  border-radius: 8px;
  margin-bottom: 20px;
}

.refund-movies h4 {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  border-bottom: 1px solid #ECECEC;
  padding-bottom: 20px;
}

.refund-movies p {
  color: rgba(0, 0, 0, 0.70);
  font-size: 14px;
  margin-bottom: 10px;
}

.refund-voucher p {
  font-size: 13px;
  color: #6C6C6C;
  margin-bottom: 15px;
}

.view-terms p {
  font-size: 13px;
  font-weight: 500;
  color: #000;
  text-decoration: underline;
}

.iconfood {
  margin-right: 14px;
}

.cancellation {
  margin-bottom: 20px !important;
}

.bill-amounts {
  display: flex;
  justify-content: space-between;
}

.seat-row-no.area {
  width: 14px;
  height: 14px;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13.5px;
  margin: 3px;
  font-family: 'Poppins';
}

/* main all seat  */

/*=== cvp style start=====*/
.offer-cvp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.offer-cvp-head h2 {
  font-size: 15px;
  margin-bottom: 0;
  color: #000;
  font-weight: 700;
}

.skip-cvp h6 {
  font-size: 12px;
  font-family: 'Poppins';
  margin-bottom: 0px;
  cursor: pointer;
}

.pvr-movie-times .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus{
  box-shadow: none;
}
.show-times h5 {
  font-size: 10.5px;
  font-weight: 800;
  color: #007D23;
  font-family: 'Poppins';
  margin-bottom: 0px;
  text-align: center;
}

.show-times p {
  font-size: 9px;
  font-family: 'Poppins';
  margin: 0;
  color: #000;
}

.icon-subtitles {
  display: flex;
}
.date-active {
  padding: 8px 17.25px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 12px;
}
.date-inactive {
  padding: 8px 17.25px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 12px;
}
.movie-name h4 {
  font-size: 12px;
  font-weight: 700;
  font-family: 'Poppins';
  color: #000;
  margin-bottom: 28.5px;
  display: flex;
  align-items: center;
}
.dot-cs {
  height: 4px;
  width: 4px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  margin-left: 6px;
}
.by-ByMovies {
  margin-right: 20px;
}
.showtimes-cinemas-name h3 {
  font-size: 10.5px;
  font-weight: 600;
  color: #000;
  font-family: 'Poppins';
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.showtimes-cinemas-name p {
  color: #000;
  font-size: 10.5px;
  font-weight: 500;
  margin-bottom: 6px;
}
.muted {
  color: #808190;
  font-weight: 400 !important;
  padding-right: 14px;
}
.show-bydates h4 {
  font-size: 12px;
  color: #000;
  font-weight: 700;
  font-family: 'Poppins';
  margin-bottom: 0px;
}
.gap-by-show{
  margin-bottom: 16.5px;
}
/* .syn {
  padding-right: 14px;
} */
.dot-ss {
  height: 4px;
  width: 4px;
  background-color: #000;
  display: inline-block;
  margin-right: 6px;
  margin-left: 6px;
}
.showtimex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pvr-movie-times .icon-subtract {
  position: absolute;
  right: 16px;
  top: 21px;
}
.pvr-movie-times .icon-add {
  position: absolute;
  right: 16px;
  top: 25px;
}

/* cvp */
.ticke-cancel-modal {
  width: 35%;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.03) !important;
  border-radius: 10px;
}
.ticke-cancel-modal .p-dialog-content{
  padding:15px  24px!important;
}
.offer-cvp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.offer-cvp-head h2 {
  font-size: 15px;
  margin-bottom: 0;
  color: #000;
  font-weight: 700;
}

.skip-cvp h6 {
  font-size: 12px;
  font-family: 'Poppins';
  margin-bottom: 0px;
  color: #545454;
  font-weight: 600;
}
.cvp-slider .carousel.carousel-slider {
  overflow: inherit;
}
.cvp-slider {
  margin-bottom: 18px;
}
.cvp-ticket {
  display: flex;
}
.cvp-quanity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.ticket-price-cvp h6 {
  color: #545454;
  font-family: 'Poppins';
  font-size: 12px;
  margin-bottom: 4px;
}
.ticket-price-cvp h5 {
  color: #000;
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 700;
  margin-bottom: 0px;
}
.quantity-cv .p-dropdown{
  position: relative;
}
.quantity-cv .p-inputtext {
  padding: 7px 18px 7px 11px;
  font-family: 'Poppins';
  color: #000;
  font-size: 14px;
}
.quantity-cv .p-dropdown .p-dropdown-trigger {
  width: 30px;
}
.quantity-cv h6 {
  font-size: 14px;
  font-family: 'Poppins';
  color: #000;
  font-weight: 600;
}
.custom-dropdown-cvp {
  position: absolute;
  top: 29px;
  right: 1px;
}
.drop-quanity {
  border-right: 1px solid #EDE8E9;
  padding-right: 20px;
}
.plus-cvp h6 {
  font-size: 21px;
  margin-bottom: 0px;
  font-weight: 700;
  color: #000;
  padding: 0px 10px;
}
.cvp-equal h6{
  font-size: 21px;
  margin-bottom: 0px;
  font-weight: 700;
  color: #000;
  padding: 0px 10px;
}
.cvp-apply {
  padding: 9px 30px;
  font-size: 13px;
  font-weight: 600;
}
.cvp-terms {
  display: flex;
  align-items: center;
}
.terms-cvp-head h6 {
  font-size: 12px;
  font-family: 'Poppins';
  color: #000;
  margin-bottom: 0px;
  text-decoration: underline;
}
.cvp-div {
  width: 100%;
  background: #EDE8E9;
  height: 1px;
  margin: 13px 0px;
} 
.heat-d {
  margin-right: 18px;
}
/*=== cvp style end=====*/
.seats-row {
  display: flex;
  align-items: center;
}

.seat-bottom-btn {
  position: fixed;
  bottom: 0px;
  width: 100%;
}
.all-grand.col-md-3 {
  padding-right: 20px;
}


.seat-terms-condition-flow {
  width: 50%;
}
.terms-conditins-flowseat ol li {
  font-family: 'Poppins';
  font-size: 14px;
  color: #000 !important;
  text-align: justify;
}

.accpet-btn-flow-seat {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -1px 0px #ebebeb;
  padding-top: 10px;
  width: 100%;
}

.reject-terms {
  padding: 9px 14px;
  border-radius: 8px;
  margin-right: 10px;
  font-family: 'Poppins';
  font-size: 13px;
  border: none;
  /* color: #000; */
}
.seat-terms-condition-flow .p-dialog-content{
  padding: 0px 0px 20px 0px;
}

.select-date {
  width: 18% ;
  height: 56px;
}

.seat-flow-custom-scroll {
  display: flex;
  flex-direction: column;
  max-height: 600px;
  /* overflow-x: scroll; */
}
.cut-price {
  text-decoration: line-through;
  color: red;
}
.show-oofer-price {
  display: flex;
}

.cut-price-show {
  margin-right: 8px;
}

.seat-terms-condition-flow-seat .p-dialog-content{
  padding: -1px;
}
.popup-catagories {
  text-align: center;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
}

.seat-layout {
  height: 400px;
  overflow-x: scroll;
  overflow-y: scroll;
  padding: 16px 20px 20px;
}

.seat-layout::-webkit-scrollbar {
  width: 5px !important;
  height: 3px !important;
}

/* Track */
.seat-layout::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.seat-layout::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.seat-layout::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.reject-terms-ok {
  border: none;
  padding: 7px 14px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Poppins';
}
.error-show-seat-catgories {
  padding: 12px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seat-current-pvr-dit {
  width: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  cursor: pointer;
  transition: transform 0.2s ease;
  margin: 2px;
}

.seat-current-inox-dit {
  width: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  cursor: pointer;
  transition: transform 0.2s ease;
  margin: 2px;
}

.seatlayout-tnc>div>p {
  font-size: 12px;
}

.seatlayout-tnc {
  width: 100%;
  overflow-y: scroll;
  height: 60vh;
}

.seatlayout-tnc::-webkit-scrollbar {
  width: 10px;
}
.seatlayout-tnc::-webkit-scrollbar-track {
  background: rgb(212, 212, 212); 
}

.seatlayout-tnc::-webkit-scrollbar-thumb {
  background: #888; 
}

.seatlayout-tnc::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.promotioin-placeholder {max-width:318px; padding-right:5px;}

@media screen and (max-width: 575px) {
.promotioin-placeholder {max-width: 300px !important; 
  padding-right:7px;

}
}