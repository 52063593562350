.headingright{
    font-size: 12px;
    font-weight: 500;
    color: #000;
    margin-bottom: 0px;
}
.headingleft{
    font-size: 12px;
    font-weight: 500;
    color: #000;
    margin-bottom: 0px;
    float: right;
}
.tkt-deatils{color:#000;font-size:14px;}
.tkt-label{padding:8px 15px;}
.tkt-value{text-align: right;padding:8px 15px;}
.tkt-strike{ text-decoration: line-through;}