.movie-name h4{    font-size: 11px;
    font-weight: 700;
    font-family: 'Poppins';
    color: #000;
    margin-bottom: 28.5px;
    display: flex;
    align-items: center;}

    .time-select {
        border: 1px solid #76BE43;
        background: rgba(0, 125, 35, 0.04);
        padding: 11px 10px 11px 10px;
        position: relative;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* height: 100%; */
        width: 100%;
        height: 50px;
    }

    .show-details {
      text-align: center;
      margin-left: 0px !important;
      display: initial !important;
      margin-top: 10px;
    }
    .show-spaces {
        margin-bottom: 18.5px;
        padding-right: 7px;
        padding-left: 7px;
    }

    /* @media (min-width: 800px) {
    .box-slot-moviesession {
        width: 100px !important;
        
      }
    } */
    .none-desktop{
        display: none;
    }
    .none-mobile{
        display: none;
    }
    .heading-movie{
        font-size: 22px;
        font-weight: 500;
        text-align: center;
        margin: 19px;
    }
    .custom-map-sidebar {
        /* height: 100vh; */
        height: -webkit-fill-available;
        width: 73vh;
        /* margin-top: 41vh; */
    }
    .showtimes-cinemas-name-map {
        width: 100%;
      }
      .showtimes-cinemas-name-map h2 {
        font-family: 'Poppins';
        font-size: 13.5px;
        font-weight: 700;
        color: #000;
        margin-bottom: 10.5px;
      }
      
      .showtimes-cinemas-name-map p {
        font-size: 10.5px;
        font-family: 'Poppins';
        color: #808190;
        margin: 0;
      }
      .box-slot-moviesession-map {
        width: 25% !important;
      }
      .map-time-select{
       /* height: 53px !important;
        width: 84px !important;*/
      }
      .icon-subtitles-map{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      .show-details-icon-map {
        text-align: center;
        margin-left: 0px !important;
        display: initial !important;
        margin-top: 10px;
    }
    .eng-map{
        position: absolute;
        bottom: 43px;
        background: #fff;
        /*left: 30%;*/
        border-radius: 3px;
    }
    .eng-map h6 {
        font-size: 9px;
        font-weight: 800;
        font-family: 'Poppins';
        padding: 3px 4px;
        margin: 0;
        color: #000;
        text-transform: uppercase;
    }
    .p-dialog-content{overflow-y: auto!important;}
    .p-sidebar .p-sidebar-content{padding:1rem;}
    @media (max-width: 575.98px) {
      .box-slot-moviesession-map {
        width: 33% !important;
      }
    
    }
