/* td{padding: 5px;} */
.QRContainer {
  border: 1px solid gray;
  padding: 10px;
  margin: 0 15px;color:#000;
}
.Qr-Voucher {
}

.vhistory-container {
  /* font-family: Arial, sans-serif; */
  margin: 20px;
}

.vhistory-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* .historuy-voucher{
  margin-bottom: 30px;
  font-family: 'Poppins',
      sans-serif;
} */

.vhistory-box {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  /* margin-top: 60px !important; */
  /* max-width: 300px; */
}

.vhistory-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.vhistory-label {
  color: gray;
}

.vhistory-value {
  font-weight: bold;
  text-align: right;
}

.vhistory-value br {
  display: none; /* Only for spacing within code, will not show on screen */
}

.passport-right h4 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.p-sidebar-header {
  /* margin-top: 20px; */
}

.voucher-details-popup .p-sidebar-header {
  padding: 0px !important;
}

.colse-btn.pvr-inox-heading h3 {
  font-size: 16px;
  color: #000;
  /* margin-top: 15px; */
  font-weight: bold;
}

/* .p-sidebar .p-sidebar-content {
  padding: 0px;
} */

.colse-btn.pvr-inox-heading {
  /* border-bottom: 1px solid #ede8e9;
  padding: 21px 15px; */
}

.passport-right-text {
  padding: 20px 0px;
  overflow-x: hidden;
}
.pvr-inox-passport-info {
  display: flex;
}

.pvr-inox-passport-info h2 {
  font-size: 18px;
  margin-bottom: 0px;
}
.qucodeimg {
  width: 180px !important;
}
.faq-question {
  width: 100%;
}
.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background: #fff;
}
.faq-answer img {
  width: 140%;
  padding-right: 10px;
}
.faq-answer td {
  padding-bottom: 20px;
}
.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background: #fff;
  border-bottom: 1px solid #dee2e6;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  border: 0;
  border-bottom: 1px solid #dee2e6;
}
.p-accordion .p-accordion-content {
  border: 0;
}
.hiw-container {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 500px;
  margin: auto;
}

.hiw-section-title {
  font-size: 1em;
  font-weight: bold;
  margin: 20px 0 10px;
  text-align: left;
  width: 100%;
}

.hiw-steps-inox {
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%;
  margin: 0;
}

.hiw-steps-pvr {
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%;
  margin: 0;
}

.hiw-steps-inox::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 2px;
  width: 2px;
  background: repeating-linear-gradient(
    #234b9e,
    #234b9e 4px,
    transparent 4px,
    transparent 8px
  );
}

.hiw-steps-pvr::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 2px;
  width: 2px;
  background: repeating-linear-gradient(#ffcb05,
      #ffcb05 4px,
      transparent 4px,
      transparent 8px);
}

.hiw-step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative;
}

.hiw-step-icon-inox {
  width: 12px !important;
    height: 12px !important;
  background-color: #234b9e;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  top: 5px;
  padding-right: 12px;
}

.hiw-step-icon-pvr {
  width: 12px !important;
  height: 12px !important;
  background-color: #ffd700;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  top: 5px;
  padding-right: 12px;
}

.hiw-step-text {
  font-size: .9em;
  line-height: 1.4;
  color: #333;
}
.check-payment-pvr a{color:#000;}
.QRCodeColTable{width: 100%;}
.faq-question h4{font-size: .8rem;font-weight: 700;}
.faq-question span{font-size: .9rem;font-weight: 500;}
.faq-answer li{font-size: .9rem;}
.QRCodeColLeft{padding-left: 20px;}
.fail-msg-box{display: flex;text-align: center;height:auto;margin: auto;}
.fail-msg-box h3{margin-bottom: 20px;font-weight: 700;font-size: 18px;}
.fail-msg-box h5{margin-bottom: 20px;font-weight: 500;font-size: 16px;}
@media(min-width: 800px){
  .QRCodeColLeft{padding-left: 20px;}
  .QRCodeColTable{width: 65%;}
}


.location h6 {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.location-details p {
  font-size: 16px;
  margin-bottom: 0;
}

.Qr-img {
  text-align: center;
}


@media (max-width: 575.98px) {
  .vhistory-container {
    margin: 0px;
  }

  .historuy-voucher {
    padding-top: 70px;
    font-family: 'Poppins',
      sans-serif;
  }
}