.all-links ul{
    display: flex;
    align-items: center;
}
.all-links ul li {
    float: left;
    padding: 6px 12px 6px 12px;
    margin-right: 3px;
    list-style: none;
    font-size: 11.5px;
    font-weight: 500;
    display: flex;
    align-items: center !important;
    cursor: pointer;
}
.disable-navbar {
    pointer-events: none;
    opacity: 0.4;
}
.show-desktop-view .p-dropdown .p-dropdown-trigger {
    width: 20px;
}

.active-nav-inox {
    background: #234B9E;
    color: #fff;
    border-radius: 30px;
}

.active-nav-pvr {
    background: #FFCB05;
    color: #000;
    border-radius: 30px;
}

.nav-icons {
    margin-right: 4px;
}

.search-current .p-inputgroup-addon {
    background: none;
    border-right: none;
    border-radius: 8px 0px 0px 8px;
    border-top: 1px solid #D6D6D6;
    border-left: 1px solid #D6D6D6;
    border-bottom: 1px solid #D6D6D6;
}

.search-current .p-inputtext {
    border-radius: 0px 8px 8px 0px;
    border-left: none;
    padding-left: 0px;
}

.before-login {
    border: none;
    height: 36px;
    padding: 10px 18px;
    /* background: #DCE2EF; */
    font-size: 9px;
    color: #000;
    font-weight: 600;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-icons {
    margin-right: 6px;
}

.login-before {
    margin-right: 15px;
    padding-top: 5px;
}

.search-main-box {
    position: relative;
    /* width: 43px; */
}

.search-icon-main-nav {
    position: absolute;
    top: 11px;
    left: 9px;
}

.register-mobile {
    width: 40%;
}

.register-mobile .cross-icon {
    right: 0px !important;
    top: 18px !important;
}

/* .logos-inox {
    height: 40px;
} */
/* img.logos-inox {
    width: 75%;
} */

.nav-icons img {
    width: 17px;
    height: 14px;
    overflow: hidden;
}

.desktop-view-M li:hover {
    background-color: #F1F1F1;
    border-radius: 20px;
}

.active-nav-inox:hover {
    background: #234B9E !important;
}

.more-content ul li {}

/* width */
.more-content::-webkit-scrollbar {
    width: 5px !important;

}

/* Track */
.more-content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.more-content::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.more-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.web-switch {
    display: flex;
    justify-content: center;
    background: #000;
    padding: 16px 0px;
}

.on-off p {
    margin-bottom: 0px;
    margin-right: 10px;
}

.website-switch-main {
    position: fixed;
    top: 3.6rem;
    color: #fff;
    /* display: flex; */
    /* justify-content: center; */
}
.election-logo img {
    margin-top: -7px;
}

.notification-bell {
    position: relative;
    padding: 7px 12px 0px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.notifi-number h2 {
    position: absolute;
    font-size: 10px;
    background: #B10000;
    color: #fff;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    top: 6px;
    right: 6px;
    margin-bottom: 0;
}

.film-fesival-nav{padding:0 10px;margin-top: 2px;}