.gift-card {
    padding: 45px 34px;
    background: #6A5332;
    border-radius: 8px;
    margin-top: 24px;
    margin-bottom: 40px;
}
.gift-explore img {
    width: 100%;
}
.gift-banner h1 {
    font-size: 22.5px;
    margin: 0;
}
.gift-center {
    display: flex;
    align-items: center;
}
.gift-content h5 {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 16px;
}

.buy-now {
    border: none;
    padding: 9.75px 30px;
    background: #fff;
    font-size: 12px;
    font-weight: 600;
    border-radius: 8px;
}
.gift-actions {
    background: #F1F1F1;
    border-radius: 8px;
    margin-top: 24px;
}
.balance {
    display: flex;
    border-bottom: 1px solid #D6D6D6;
    padding: 0px 24px 14px 16px;
}
.action-gift h6 {
    color: #808190;
    font-weight: 500;
    font-size: 12px;
    padding: 15px 9px 8px 21px;
}
.check-balance {
    margin-right: 16px;
}
.gift-btn {
    border: none;
    background: #234B9E;
    color: #fff;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    padding: 9px 15px;
    border-radius: 5px;
}
.my-gift-btn {
    background: #234B9E;
    padding: 9px 15px;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
}

.how-it {
    display: flex;
    justify-content: space-between;
    padding: 14px 19px 14px 20px;
    border-bottom: 1px solid #ddd;
}

.how-it:last-child{
    border-bottom: none;
}
.work h5 {
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 600;
}

.work p {
    font-size: 11px;
    margin: 0;
}

.gift-coupon .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #000;
}
.gift-coupon .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus{
    box-shadow: none;
}
.gift-coupon .p-tabview .p-tabview-nav .p-tabview-ink-bar{
    /* background-color: #234B9E; */
}
.gift-coupon .p-tabview .p-tabview-nav li .p-tabview-nav-link{
    color:#808190;
    font-weight: 600;
}
.gift-contents p {
    font-size: 12px;
    margin: 0;
}
.gift-voucher img {
    width: 100%;
    margin-bottom: 34px;
    cursor: pointer;
}

/* breadcrumb style start*/
.seat-bread-gift ul {
    display: flex;
    align-items: center;
  }
  
  .seat-bread-gift ul li {
    float: left;
    list-style: none;
    font-weight: 500;
    font-size: 10px;
    margin-right: 60px;
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
  }
  
  /* .seat-bread-gift ul li:not(:first-child, :last-child)::after {
    content: url("../../assets/seatlayout/arrow_right.svg");
    position: absolute;
    margin-left: 22px;
  } */
  
  .seat-bread-gift {
    /* background: #dce2ef; */
    padding: 14px 14px;
  }
  /* breadcrumb style end*/
/* gift details start */
.cards-gift{
    margin-top: 24px;
}
.discount-gift {
    padding: 11px 0px;
    background: #007D23;
    margin-bottom: 4px;
    text-align: center;
    border-radius: 8px 8px 0px 0px;
}
.discount-gift h6 {
    margin-bottom: 0px;
    color: #ffff;
    font-size: 12px;
}

.per-icon {
    margin-right: 8px;
}
.gift-details img {
    width: 100%;
    margin: 0 !important;
    border-radius: 8px;
}
.add-amount-gifts h4 {
    font-size: 14.5px;
    font-weight: 700;
    margin-bottom: 12px;
}
.enter-amount {
    background: #F1F1F1;
    padding: 18px;
    border: 1px solid #DDD;
    border-radius: 8px;
    margin-bottom: 12px;
}
.add-money {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10.5px;
}

.form-amount {
    height: 36px;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding-left: 22px;
    font-size: 13px;
}
.form-amount::placeholder {
    font-size: 13px;
    color: #908E8E;
}
.money-add {
    position: relative;
}

.rupee-icon {
    position: absolute;
    top: 25%;
    left: 4%;
}

.rupee-icon h6 {
    margin: 0;
    font-size: 14px;
}
.btn-text {
    border: none;
    background: #234B9E;
    color: #fff;
    padding: 6px 35px;
    border-radius: 5px;
    width: 100%;
}
.money-add input:focus-visible {
    outline: none !important;
}
.add-contents p {
    margin: 0;
    font-size: 12px;
    color: #808190;
}
.customies-coupon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.custom {
    color: #808190;
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.coupon-shows h6 {
    font-size: 14px;
    margin-bottom: 0;
}
.coupon-photo {
    width: 15%;
    margin-right: 10.5px;
}
.s-dots {
    width: 4px;
    height: 4px;
    display: inline-block;
    background: #808190;
    margin-right: 5px;
}
.coupon-shows {
    display: flex;
    align-items: center;
    width: 70%;
}
.coupon-cart {
    width: 30%;
    text-align: right;
}
.enter-amounts {
    background: #f1f1f1;
    border: 1px solid #ddd;
    border-radius: 8px;
}
.divider-coupon {
    width: 100%;
    height: 1px;
    background: #E1E1E1;
}
.shoow-coupon {
    background: #F1F1F1;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 20px;
}
.coupons-view-all {
    padding: 18px;
}
.coupon-grand {
    padding: 15px 0px 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.grand-total-head{
    display: flex;
    justify-content: space-between;
}
.grand-total-head h6 {
    font-size: 12px;
    margin: 0px;
}

.coupon-grand-price h6 {
    font-size: 12px;
    font-weight: 700;
    margin: 0;
}

.price-cut {
    font-weight: 400;
    color: #000;
    text-decoration: line-through;
    margin-right: 5px;
}
.add-details h3 {
    font-size: 13.5px;
    font-weight: 700;
    color: #808190;
    margin-bottom: 15px;
}
.gifting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 15px;
  }
  .else-gift h5 {
    font-size: 13px;
    margin: 0;
    font-weight: 600;
  }
  .gift-proce {
    width: 100%;
    border: none;
    padding: 11px 0px;
    border-radius: 6px;
}
.gift-contents {
    background: #F1F1F1;
    border-radius: 0px 0px 9px 9px;
    padding: 15px 15px 18px 15px;
}

.gift-content p {
    font-size: 12px;
    text-align: justify;
    margin: 0;
}
.order-summary h4 {
    font-size: 13.5px;
    font-weight: 700;
    margin-bottom: 12px;
}
.recpient h6 {
    font-size: 12px;
    margin-bottom: 15px;
}
.sub-view {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.sub-total h6 {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 12px;
}

.sub-price h6 {
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 500;
}

.recpet-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.recepit-name h6 {
    color: #908E8E;
    font-weight: 400;
    font-size: 12px;
    margin: 0;
}

.recepit-user h6 {
    color: #000;
    font-size: 12px;
    margin: 0;
}
.coupon-cart h5 {
    font-size: 12px;
    margin: 0;
    color: #908E8E;
}
.value-line {
    margin-bottom: 15px;
}
.dashed-line {
    border: 1px dashed #ddd;
    margin-bottom: 7.5px;
}
.rudec-view {
    margin-bottom: 7.5px;
}

.tobepaid {
    padding: 15px 0px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}
.disc-offer {
    color: #007D23;
}
.terms-checkbox label {
    color: #000;
    font-size: 12px;
    margin-left: 0px;
    margin-top: 15px;
}
.terms-checkbox .p-checkbox .p-checkbox-box {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid #000;
    margin-top: 2px;
}
.terms-checkbox .p-checkbox .p-checkbox-box.p-highlight{
    background: #000;
}
.pay-proce {
    padding: 6px 10px;
    border: none;
    border-radius: 5px;
    width: 100%;
}
.grand-total-head p {
    font-size: 12px;
    margin: 0;
}
.price-lastest {
    font-size: 13.5px !important;
    font-weight: 700;
}

.rupees-icon {
    font-weight: 400;
}
.gift-coupon-conditions .p-sidebar-content {
    padding: 0px !important;
}
.terms-spaces {
    padding: 0px 20px;
}
.terms-content{
    padding: 7.5px 20px;
}
.terms-content p {
    font-size: 12px;
    color: #000;
    text-align: justify;
    margin-bottom: 10px;
}
.accept-condition {
    padding: 10px 20px;
}
.accept-policy {
    border: none;
    width: 100%;
    padding: 6px 6px;
    border-radius: 5px;
    font-family: 'Poppins';
    font-size: 14px;
}
.my-balance {
    background: #D6D6D6 !important;
    width: 100%;
    height: 1px;
}
.card-balance {
    padding: 15px 20px 10px 20px;
}
.showmore-btn {
    border: none;
    width: 100%;
    padding: 6px 10px;
    border-radius: 5px;
    font-family: 'Poppins';
    font-size: 14px;
}
.gift-card-show {
    padding: 10px 20px;
    margin-bottom: 8px;
}
.avialable-balance {
    background: #D9ECDE;
    border-radius: 0px 0px 9px 9px;
    border: 1px solid rgba(26, 140, 45, 0.04);
    padding:11px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.avail-text h6 {
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
    color: #000;
}
.avail-price h6 {
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
    color: #000;
    font-weight: 600;
}
.gift-coupon-expire {
    padding: 0px 20px;
}
.active-card {
    background: #F8F8F8;
    border: 1px solid #EDE8E9;
    border-radius: 9px;
    margin-bottom: 24px;
}
.proper-card{
    display: flex;
    justify-content: space-between;
    padding: 6px 14px;
    align-items: center;
}
.card-no h6 {
    font-size: 12px;
    margin: 0;
    font-family: 'Poppins';
    color: #000;
}

.card-code h6 {
    font-size: 12px;
    font-family: 'Poppins';
    color: #000;
    font-weight: 600;
    margin-bottom: 0px;
}
.expiry-date-coupon {
    padding: 7.5px 14px;
}
.gift-to {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7.5px;
}
.gift-to:last-child{
    margin-bottom: 0px;
}
.gift-date h6 {
    color: #7A7A7A;
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
}
.gift-amount h6{
    color: #000;;
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
}
.transaction-history {
    padding: 0px 20px;
}
.transaction-head h6 {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: 'Poppins';
    color: #000;
    margin-bottom: 12px;
}
.transaction-card-box {
    border: 1px solid #EDE8E9;
    border-radius: 9px;
    box-shadow: 0px 1px 0px #EDE8E9;
    margin-bottom: 15px;
}
.transaction-poster {
    display: flex;
    background: #F9F9F9;
    border-bottom: 1px solid #EDE8E9;
    padding: 12px 14px;
}
.transaction-movies-details h4 {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin-bottom: 6px;
}
.transaction-movies-details ul li {
    float: left;
    font-size: 12px;
    list-style: none;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    margin-bottom: 6px;
}
.transaction-movies-details ul li:first-child{
    margin-left: 0px;
}

.transaction-movies-details p {
    font-size: 12px;
    margin: 0;
    color: #000;
    margin-bottom: 0px;
}
.poster-img img {
    margin-right: 12px;
}
.gift-dots {
    width: 4px;
    height: 4px;
    background: #7A7A7A;
    border-radius: 50%;
    display: block;
    margin-right: 5px;
}
.show-places {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.gift-food {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 14px;
}

.gift-veg h6 {
    font-size: 12px;
    margin: 0;
    font-family: 'Poppins';
    font-weight: 600;
    color: #000;
}

.gift-items-show p {
    font-size: 12px;
    margin: 0;
    color: #000;
}
.icon-foods {
    margin-right: 4px;
}
.gift-veg p {
    font-size: 12px;
    margin: 0;
    color: #000;
}
.order-id {
    padding: 6px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-name h6 {
    font-size: 12px;
    font-family: 'Poppins';
    color: #7A7A7A;
    margin: 0;
}

.order-code h6 {
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
    color: #7A7A7A;
}
.total-bill {
    background: #F9F9F9;
    padding: 6px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.total-bills h6 {
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
    font-weight: 500;
    color: #000;
}

.total-price h6 {
    font-size: 12px;
    font-weight: 700;
    font-family: 'Poppins';
    color: #000;
}
.my-balance-more {
    padding: 6px 14px;
    text-align: center;
}
.cupon-show-more {
    padding: 9px 15px;
    border: none;
    background: #DCE2EF;
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px;
    font-family: 'Poppins';
}
.date-active {
    padding: 8px 17.25px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 12px;
}
.date-inactive {
    padding: 8px 17.25px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 12px;
}
.sort-dropdown .p-dropdown {
    width: 100%;
    height: 36px;
    border-radius: 5px;
    background: #F1F1F1;
    border: 1px solid #ddd;
}
.my-cards {
    padding: 6px 14px;
}
.sort-dropdown {
    margin-bottom: 12px;
}
.sort-dropdown .p-inputtext{
    padding: 8px 10px;
}
.sort-dropdown .p-inputtext {
    padding: 6px 14px;
}
.sort-dropdown .p-dropdown .p-dropdown-label.p-placeholder {
    color: #000;
    font-size: 12px;
    font-family: 'Poppins';
    padding: 8px 10px;
}
.p-dropdown-items .p-dropdown-item.p-highlight{
    border-radius: none !important;
}
.p-dropdown-panel.p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover{
    border-radius: none !important;
}
.sort-dropdown .p-dropdown:not(.p-disabled).p-focus{
    border-color:#ddd !important;
}
.gift-event-content {
    border: 1px solid #EDE8E9;
    border-radius: 0px 0px 9px 9px;
}
.gifted{
    padding: 8px 10px;
}
.gift-seat{
    margin-bottom: 18px;
}
.my-gift-viewed {
    background: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
}

.number-card h6 {
    font-size: 12px;
    font-family: 'Poppins';
    color: #000;
    font-weight: 600;
    margin: 0;
}
.icon-rightside {
    margin-left: 5px;
}
.views-gift-details h6 {
    font-size: 12px;
    font-family: 'Poppins';
    color: #000;
    margin: 0;
}
.step-by-step{
    padding: 11px 20px;
}
.step-icon {
    margin-right: 12px;
}
.how-it-step {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    position: relative;
}

.step-content h6 {
    font-size: 12px;
    font-family: 'Poppins';
    color: #000;
    font-weight: 600;
    margin-bottom: 4px;
}

.step-content p {
    font-size: 12px;
    margin: 0;
    color: #7A7A7A;
}
.step-icon {
    margin-right: 12px;
    background: #DCE2EF;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #234B9E;
}
.how-it-step::before {
    content: "";
    width: 1px;
    height: 30px;
    background: #234B9E;
    position: absolute;
    bottom: -33px;
    left: 24px;
}
.how-it-step:last-child::before {
    display: none;
  }
  .bulk-card {
    padding: 20px 75px;
}
.bulk-card-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}
.bulk-head h2 {
    font-size: 25.5px;
    font-weight: 800;
    color: #000;
    margin-bottom: 0;
}
.bulk-contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-details p {
    font-size: 12px;
    margin: 0;
    color: #000;
}

.icon-mail {
    margin-right: 5px;
}
.bluk-sub {
    border: none;
    padding: 9px 30px;
    border-radius: 5px;
    font-family: 'Poppins';
    font-size: 12px;
}

.close-btn-bluk {
    border: 1px solid #D6D6D6;
    background: #fff;
    padding: 5px 10px;
    font-family: 'Poppins';
    font-size: 12px;
    border-radius: 5px;
}.bulk-close i {
    font-size: 12px;
    margin-left: 5px;
}
/* gift details end */