.resend-otp h6 {
    margin-bottom: 18px;
}
.p-errors {
    color: #e24c4c;
    position: absolute;
    font-size: 12px;
    font-family: 'Poppins';    
}

.register-mobile::-webkit-scrollbar {
    width: 0px !important;
    background: transparent !important;
  }

  .sweet-alert {
    width: 26rem !important;
    font-size: 13px;
    color: #000;
}

.account-sign-up p {
    color: #908E8E !important;
    margin-top: 20px;
    font-size: 14px !important;
    font-weight: 500;
}
.btn-sign-up {
    border: none;
    background: none;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    text-decoration: underline;
}
.google-login {
    width: 100% !important;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.separator {
    display: flex;
    align-items: center;
    width: 75%;
    margin: 0 auto;
    margin-top: 15px;
}
  
.side-line {
    height: 2px;
    flex: 1 1;
    background-color: #ede8e9;
    width: 100%;
    margin: 0 auto;
}
  
.separator h2 {
    padding: 6px 6px 0px 6px;
    font-size: 14px;
    color: #908E8E;
}

/* signup from */
.social-signup {
    padding: 30px 0px 0px 0px;
}
.sign-up-form-social h2 {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}
.autofill {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
}

.google-icon ul li {
    list-style: none;
    float: left;
    margin-right: 9px;
}
.autofill-info h5 {
    font-size: 14px;
    font-family: 'Poppins';
}
.form-socail-signup {
    margin-top: 20px;
}
.login-proceed-btn{
    border: none;
  padding: 10px 12px;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  border-radius: 7px;
  width: 100%;
  cursor: pointer;
}
.social-proceed {
    border: none;
    padding: 11px;
    width: 100%;
    font-family: 'Poppins';
    font-weight: 600;
    border-radius: 6px;
}
.fields-search .p-calendar {
    width: 100%;
}
.mobile .social-login .p-float-label label {
    top: 22%;
}
.google-login .haAclf {
    display: contents !important;
    border-radius: 13px;
}
.organisation-feild .dropdowns-type{
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #908E8E;
}
/* signup from */
