.custom-sidebar {
    position: relative;
  }
  .custom-sidebar button.p-sidebar-close {
    position: absolute;
    top: 21px;
    left: 10px;
}
.cities-placed {
  color: #000 !important;
}
.p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon{
  color:#000000 !important;
  border-radius: 0px !important;
}
  
  .select-city h4 {
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Poppins';
    color: #000;
   margin-bottom: 0px;
}
.search-current .p-inputtext {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  display: block !important;
  border-top: 1px solid #D6D6D6;
  border-right: 1px solid #D6D6D6;
  border-bottom: 1px solid #D6D6D6;
}
.search-current  .p-inputtext:enabled:hover{
  border-color: #ddd !important;
}
.search-current .p-input-icon-left, .p-input-icon-right{
  display: block !important;
}
.search-current .p-inputtext:enabled:focus{
  box-shadow: none !important;
  border-color: #D6D6D6;
}
.search-current .p-input-icon-left > i:first-of-type, .p-input-icon-left > svg:first-of-type, .p-input-icon-left > .p-input-prefix{
  left: 0.75rem;
  color: #7A7A7A;
}
.p-sidebar .p-sidebar-header .p-sidebar-close:focus, .p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}
.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover, .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover{
  background: none !important;
}

.p-sidebar .p-sidebar-header {
  padding: 10px 0px;
}
  
.search-current {
  margin-bottom: 24px;
  position: relative;
}
.cities-name {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 700;
}

.cities-names {
  position: relative;
  margin-bottom: 7px;
}
.cities-names img {
  border-radius: 10px;
  width: 100%;
}
.plr-cities {
  padding-right: 4px;
  padding-left: 4px;
}
.cities-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(25deg, #000 8.81%, rgba(175, 175, 175, 0.11) 81.37%); */
  border-radius: 10px;
  cursor: pointer;
}
.cities-part {
  padding-top: 42px;
  text-align: center;
}
.cities-head h3 {
  position: relative;
  background: #fff;
  padding: 2px 6px;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  z-index: 999 !important;
  color: #7A7A7A;
}

.other-cities {
  position: relative;
  background: #fff;
  z-index: 2;
  padding: 2px 6px;
}

.cities-head h3::after{
    content: "";
    width: 100%;
    height: 2px;
    border-radius: 5px;
    background: #E2E2E2;
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 0;
}

.sub-cities-list{
  padding: 5px 10px 5px 30px;
}

.sub-cities-list ul {
  list-style: none;
  display: flex;
  padding-left: 0;
  flex-wrap: wrap;
  width: 100%;
}

.sub-cities-list ul li {
  color: #000;
  width: 50% !important;
  margin: 5px 0;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
}

.other-cities-list{
  padding: 0px 0px 0px 30px;
}
.other-cities-list ul {
  list-style: none;
  display: flex;
  padding-left: 0;
  flex-wrap: wrap;
  width: 100%;
} 
.other-cities-list ul li a {
  width: 50% !important;
  text-decoration: none;
  display: block;
  text-align: left;
  cursor: pointer;
  color: #000;
  font-size: 14px;
    font-weight: 500;
    padding: 0px 25px;
}
  
.other-cities-list {
  padding-top: 36px;
}

.other-cities-list ul li {
  color: #000;
  width: 50% !important;
  margin-bottom: 26px;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
}
  
.current-location {
  position: absolute;
  top: 9px;
  right: 15px;
  z-index: 99999;
}
.cities-show {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
/* .cities-active {
  background: linear-gradient(25deg, #234B9E 24.63%, rgba(0, 0, 0, 0.11) 84.38%);
  border: 2px solid #234B9E;
  color: #fff !important;
} */

.down-arrow {
  width: 81px !important;
  display: inline-block;
  margin-left: 2px;
}
.show-mobile-view h6 {
  font-size: 12px;
}


.p-autocomplete-panel {
  z-index: 999999 !important;
}