body{
  overflow-x: hidden;
}

.payment-spaces {
  padding: 22.5px 22.5px 45px 22.5px;
}



.payment-method {
  padding: 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.security_code {
  font-size: 16px;
  width: 60px;
  color: #000 !important;
}

.payment-method ul li {
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  margin-bottom: 12px;
  padding: 6.25px 3.25px;
}
/* .payment-method ul li:hover {
    background: #DCE2EF;
    padding: 13px 15px;
    border-radius: 5px;
} */
.payment-active-pvr {
  background: #FFF0D6;
  padding: 6.25px 6.25px;
  border-radius: 5px;
}
.payment-active-inox {
  background: #dce2ef;
  padding: 6.25px 6.25px;
  border-radius: 5px;
}
.payment-inactive {
  padding: 13px 15px;
  border-radius: 5px;
}
.paytm-photo {
  margin-right: 18px;
}
.payment-method h6 {
  font-size: 12px;
  color: #7a7a7a;
  margin-bottom: 10.5px;
  font-weight: 500;
  text-transform: uppercase;
}
.payment-form {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
}

.paywithdebit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 18px;
  border-bottom: 1px solid #ddd;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
}
.debit-card h6 {
  margin: 0;
}
.to-paid p {
  margin: 0;
}
.debit-card h6 {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}

.to-paid p {
  font-size: 12px;
  font-weight: 400;
}

.price-cross {
  font-weight: 500;
  color: #1a8c2d;
  text-decoration: line-through;
  margin-left: 8px;
  margin-right: 8px;
}
.price-blod {
  font-weight: 700;
}
.payment-debitcard {
  padding: 24px 72px;
  background: #fff;
  border-radius: 0px 0px 8px 8px;
}
.payment-debitcard .p-chips {
  display: block;
}
.payment-feild {
  width: 100%;
  position: relative;
  /* display: flex;
    align-items: center;
    justify-content: center; */
}
.remove-cricle {
  position: absolute;
  top: -9px;
  right: -9px;
}
.payment-debitcard .p-float-label {
  display: block;
  position: relative;
  /* width: 80%; */
  margin-bottom: 12px;
}
/* .invalid {
  border : 2px solid #ed1313 !important;
} */
.payment-debitcard .p-chips .p-chips-multiple-container {
  padding: 12px 11.25px;
  border: 1px solid #EDE8E9;
}
.payment-debitcard
  .p-chips
  .p-chips-multiple-container:not(.p-disabled).p-focus {
  box-shadow: none;
  border: 1px solid #ddd;
  border-color: #ddd;
}
.payment-debitcard .p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #ddd;
}
.payment-debitcard .p-float-label input:focus ~ label,
.p-float-label input:-webkit-autofill ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label,
.p-float-label .p-tooltip-target-wrapper ~ label {
  top: 12px;
}
.p-float-label input:focus ~ label, .p-float-label input:-webkit-autofill ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label, .p-float-label .p-tooltip-target-wrapper ~ label {
  top: 0.9rem;
  font-size: 12px;
}
.payment-debitcard
  .p-chips
  .p-chips-multiple-container
  .p-chips-input-token
  input {
  font-weight: 600;
  font-family: "Poppins";
  color: #000;
  font-size: 12px;
}
.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 13px;
  cursor: pointer;
}
.payment-feild .p-float-label label{
  font-size: 12px !important;
}
.p-float-label label{
  font-size: 12px !important;
}
.payment-feild .p-chips .p-chips-multiple-container .p-chips-input-token{
  padding: 5px 0px;
}
/* Hide the default checkbox input */
.checkbox-container input {
  display: none;
}

/* Create the custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #007d23;
  border-radius: 3px;
}

/* Style the custom checkbox when checked */
.checkbox-container input:checked ~ .checkmark:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Style the label text */
.label-text {
  font-size: 16px;
  color: black;
}
.check-securely {
  display: flex;
  align-items: center;
  margin-bottom: 19.5px;
}
.check-securely h6{
  font-size: 12px;
  margin-bottom: 0px;
}
.verfiy-payment {
  width: 100%;
  border: none;
  padding: 13px 0px;
  border-radius: 8px;
  /* background: #234b9e;
  color: #fff; */
  font-weight: 600;
  font-size: 13px;
}
.explore-offer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ede8e9;
  padding: 10px 14px;
  border-radius: 8px;
  height: 51px;
    margin-top: 3px;
}

.apply-coupon {
  padding: 11px 9px;
  background: #fff;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 8px;
}

.offericon h6 {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 500;
}
.explore-icon {
  margin-right: 8px;
}
.offers-start {
  border: 1px solid #ede8e9;
  margin-bottom: 4.5px;
  margin-top: 3px;
  padding: 7.5px 14px;
  display: flex;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  background:#fff ;
}
.offer-control {
  border: none;
  font-size: 12px;
  width: 100%;
  font-weight: 600;
  color: #000;
  font-family: "Poppins";
}
.btn-apply {
  width: 100%;
  border: none;
  padding: 8px 14px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 12px;
}
.coupon-input {
  width: 74%;
  height: 30px;
}
.apply-offers {
  width: 22%;
}
.offer-control:focus-visible {
  outline: none;
  padding: 9px 8px;
}
.bill-amounts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.gst h6 {
  margin-bottom: 0px;
}
.tax-gst h6 {
  margin-bottom: 0px;
}
.payment-price-cut {
  font-weight: 500;
  margin-right: 8px;
  text-decoration: line-through;
  color: #1a8c2d;
}
.cauvery-call {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ticket-call h5 {
  font-size: 14px;
  margin-bottom: 5px;
}

.ticket-call p {
  font-size: 12px;
  margin: 0;
}

.price-call p {
  font-size: 14px;
  margin-bottom: 5px;
}
span.info {
  margin-left: 6px;
}
.border-dashed {
  border: 1px dashed #ede8e9;
  margin: 14px 0px;
}
.saved-amount {
  text-align: center;
  padding: 25px 0px;
}

.checked-applied {
}

.check-icon {
  margin-bottom: 8px;
}

.cancel-payment {
  width: 100%;
  border: none;
  padding: 10px;
  font-weight: 700;
  font-size: 20px;
  font-family: poppins;
}

.checked-applied p {
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 18px;
}

.promo {
  margin-bottom: 18px !important;
}

.saved-amount h4 {
  font-size: 15px;
  font-weight: 700;
  color: #000;
  margin-bottom: 4px;
}
button.awesome {
  padding: 9px 32px;
  border-radius: 30px;
  border: none;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  /* background: #234b9e; */
  font-family: "Poppins";
}

.offer-your {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 26px;
}
.offers-cross i {
  color: #000 !important;
}
.offer-line {
  background: #d6d6d6;
  width: 100%;
  height: 1px;
}
.offer-title h5 {
  font-size: 16px;
  font-weight: 700;
  font-family: "Poppins";
}

.offer-cross i {
  color: #000 !important;
}
.offer-cards {
  margin-bottom: 16px;
}

.offer-card-photo img {
  border-radius: 6px 6px 0px 0px;
}

.offer-cards-content {
  background: #fff;
  box-shadow: 0px 1px 0px #e8e8e8;
  border: 1px solid #e8e8e8;
  padding: 10px 14px;
  border-radius: 6px;
}

.arrow-offer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow-head h6 {
  font-size: 13px;
  font-weight: 700;
  color: #000;
  font-family: "Poppins";
  margin-bottom: 12px;
}

.offer-cards-content p {
  font-size: 12px;
  margin-bottom: 2px;
}
.p-sidebar-content::-webkit-scrollbar {
  width: 1px;
}
.remove {
  color: #dd0000;
  cursor: pointer;
}
.arrow-heads {
  border-bottom: 1px solid #ede8e9;
}
.arrow-heads h6 {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  font-family: "Poppins";
  margin-bottom: 5px;
  margin-top: 16px;
}

.arrow-heads p {
  font-size: 12px;
  margin-bottom: 2px;
  padding-bottom: 12px;
}
.how-avial {
  margin-top: 16px;
  margin-bottom: 24px;
}

.how-avial h4 {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.how-avial ol li {
  font-size: 12px;
  font-family: "Poppins";
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 5px;
  margin-left: 10px;
}
.offer-applys {
  /* color: #fff; */
  width: 100%;
  border: none;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins";
  /* background: #234b9e; */
  border-radius: 8px;
  padding: 10px 14px;
}
/* final-ticket */
.final-ticket-bg {
  background: #e0e6f1;
  height: auto;
}
.enjoy-show {
  text-align: center;
  padding: 41px 0px 0px 0px;
}

.enjoy-show img {
  margin-bottom: 8px;
}

.enjoy-show h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}

.enjoy-show p {
  font-size: 12px;
    margin-bottom: 12px !important;
    width: 50%;
    margin: 0 auto;
    color: #000;
    font-weight: 400;
}

.download-ticket {
  border: none;
  padding: 11px 13px;
  /* background: #234b9e; */
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
  /* color: #fff; */
  margin-bottom: 24px;
}
.final-ticket {
    background: #fff;
    box-shadow: 0px 2px 0px #DDD;
    border: 0.5px solid rgba(125, 125, 125, 0.50);
    border-radius: 19px;
    /* padding: 20px; */
    margin-bottom: 40px;
}
.final-ways {
  border-right: 1px dashed #D6D6D6;
}
.final-ticket-movies {
    display: flex;
    padding: 20px;
}
.final-ticket-img {
    margin-right: 20px;
}
.seat-info-show{
  padding: 20px 20px;
}

.final-ticket-details{
    display: grid;
}

.final-ticket-details h4 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 12px;
}

.final-ticket-details ul li {
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    margin-right: 8px;
    font-size: 12px;
    margin-bottom: 12px;
}
.ticket-content p {
    font-size: 12px;
    margin-bottom: 60px;
}
.border-down {
    border-bottom: 1px solid #BFC0C7;
    width: 100%;
}
.date-show {
    padding: 30px 0px 0px 0px;
}
.date-show h4 {
    font-size: 18px;
    font-weight: 400;
}
.time-show {
  font-weight: 600;
}
.date-show h5 {
  font-size: 15px;
  font-weight: 400;
}
.day-show{
    font-weight: 700;
}
.day-show {
    font-weight: 700;
    margin-right: 10px;
}
.date-show h5 {
    font-weight: 400;
}
.history-book {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.book-history p {
    margin: 0;
    text-decoration: underline;
    font-weight: 600;
    color: #000;
    font-size: 12px;
}
.download-tickets {
  margin-right: 10px;
}

.download-tickets img {
  margin-bottom: 0px;
}
.select-seat-number-final {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
}
.veg-food h6 {
  margin: 0px;
}
.seat-number-final{
  padding: 7px 8px;
  background: #e0e6f1;
  display: flex;
  align-items: center;
  margin-right: 8px;
  border-radius: 5px;
  margin-bottom: 8px;
}
.seat-number-final p {
  font-weight: 600;
  margin: 0;
  font-size: 12px;
}
.seat-info-show h6 {
  font-size: 11px;
  color: #808190;
  margin-bottom: 10px;
}

.seat-info-show h5 {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
}
.regular-food.final {
  margin-bottom: 9px !important;
}
.orderid h6 {
  font-size: 11px;
  color: #808190;
  margin-bottom: 10px;
}

.orderid h5 {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
} 
.kiosk {margin-bottom: 37px;}
.kiosk h6 {
  font-size: 11px;
  color: #808190;
  margin-bottom: 10px;
}

.kiosk h5 {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
}

.sanner {
  padding: 20px 20px;
  border-left: 1px dashed #BFC0C7;
  height: 100%;
}
.all-type-payment {
  background: #F1F1F1;
  padding: 10.5px 18px;
  border-radius: 8px;
  /* margin-top: 20px; */
  margin-top: 0px;
}
.payment-postpaytm {
  background: #fff;
  padding: 18px;
  border-radius: 8px;
}
.payment-postpaytm .resend-otp {
  margin-top: 22.5px;
}
.payment-postpaytm .btn-proceeds {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.payment-postpaytm .otp-feild input {
  margin-bottom: 18px;
}
.payment-netbanking {
  padding: 12px 18px;
  background: #fff;
  border-radius: 8px;
}
.bank-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bank-name h6 {
  margin-bottom: 0px;
  font-size: 12px;
}
.bank-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}
.bank-divider {
  background: #EDE8E9;
  height: 1px;
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
}
.bank-name {
  display: flex;
  align-items: center;
}
.payment-type-icon {
  width: 31px;
  margin-right: 25px;
}
.upi-feilds .p-chips {
  display: inherit;
  margin: 0 auto;
}
.upi-feilds {
  margin: 0 auto;
  width: 100%;
  margin-bottom: 18px;
}
.upi-flow {
  width: 80%;
  margin: 0 auto;
}
.upi-collect p {
  font-size: 12px;
  color: #908E8E;
  margin-top: 5px;
}
.card-cred input {
  width: 100%;
  height: 54px;
  border: 1px solid #EDE8E9;
  font-size: 12px;
  color: #908E8E;
  padding: 0px 12px;
  margin-bottom: 12px;
  border-radius: 8px;
}
.information-mobikwik {
  text-align: left;
  color: #908E8E !important;
  margin-bottom: 18px !important;
}
.gyftr-box {
  background: #F8F8F8;
  border: 1px solid #ECECEC;
  border-radius: 8px;
}

.gyftr-box p {
  font-size: 12px;
  padding: 8px;
  margin-bottom: 0;
}

.gyftr-box {
  margin-bottom: 15px;
}
.divider-gyftr {
  width: 100%;
  background: #ECECEC;
  height: 1px;
}
.otp-feild {
  justify-content: center;
  display: flex;
}
.otp-feild input{
  margin-left: 18px;
}
.verfiy-paytm {
  width: 70% !important;
}
.payment-netbanking .payment_method {
  /* width: 50%; */
  height: 36px;
  border-radius: 8px;
  font-size: 12px;
  border: 1px solid #ede8e9;
  color: #000;
}
.make_payment {
  margin-left: 10px;
  padding: 8px 32px;
  font-size: 12px;
  border: none;
  /* background: #234b9e; */
  border-radius: 6px;
  /* color: #fff; */
  font-weight: 600;
}
.payment_method:focus-visible{
  outline: none !important;
}
.terms-condition-payment p {
  font-size: 12px;
  margin-bottom: 0px;
  padding-top: 3px;
}

.terms-condition-payment a {
  color: #000;
  font-weight: 600;
}
.terms-condition-payment {
  margin-left: 10px;
}
.terms-payment {
  display: flex;
  align-items: center;
  margin-bottom: 10px ;
  margin-top: 10px;
}

.check-payment {
  padding-top: 5px;
  margin-right: 10px;
}
.field-checkbox {
  margin-bottom: 10px;
  font-size: 12px;
}
.field-checkbox label {
  margin-left: 10px !important;
}
.payment-feild p {
  font-size: 12px;
  margin-bottom: 10px;
}
.card_number_div{
  border: 1px solid #ede8e9;
  border-radius: 8px;
  color: #908e8e;
  font-size: 12px;
  height: 54px;
  margin-bottom: 12px;
  width: 100%;

}
.card_exp_month_div{
  border: 1px solid #ede8e9;
  border-radius: 8px;
  color: #908e8e;
  font-size: 12px;
  height: 54px;
  margin-bottom: 12px;
  width: 100%;

}
.card_exp_year_div{
  border: 1px solid #ede8e9;
  border-radius: 8px;
  color: #908e8e;
  font-size: 12px;
  height: 54px;
  margin-bottom: 12px;
  padding: 0px 12px;
  width: 100%;
}
.security_code_div{
  border: 1px solid #ede8e9;
  border-radius: 8px;
  color: #908e8e;
  font-size: 12px;
  height: 54px;
  margin-bottom: 12px;
  padding: 0px 12px;
  width: 100%;
}
.name_on_card_div{
  border: 1px solid #ede8e9;
  border-radius: 8px;
  color: #908e8e;
  font-size: 12px;
  height: 54px;
  margin-bottom: 12px;
  padding: 0px 12px;
  width: 100%;
  font-family: Poppins;
}
.card_number {
  padding: 0px 12px !important;
  font-family: Poppins;
  outline: none !important;
}

.card_exp_month {
  padding: 0px 12px !important;
  font-family: Poppins;
}
.noofcoupon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.noofcoupon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.coupon-no h6 {
  margin-bottom: 0px;
  font-size: 14px;
}
.mobile .p-float-label input:focus ~ label, .p-float-label input:-webkit-autofill ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label, .p-float-label .p-tooltip-target-wrapper ~ label {
  top: 14px;
}
.payment-feild .offer-control {
  border: 1px solid #EDE8E9;
  border-radius: 8px;
  height: 54px;
}

.upi-feilds .form-control {
  height: 54px;
}
.passport-ticket img {
  width: 100%;
  margin-bottom: 60px;
}

.final-ticket-new {
  background: #fff;
  border: 1px solid rgba(125, 125, 125, 0.50);
  box-shadow: 0px 2px 0px #DDD;
  border-radius: 10px;
}
.ticket-new-box {
  padding: 20px 20px;
}

.movie-poster-new img {
  width: 100%;
  margin-bottom: 12px;
}

.share-qr-code img {
  width: 100%;
}
.ticket-new-box {
  padding: 20px 20px;
  border-right: 1px dashed #D6D6D6;
  height: 100%;
}

.final-ticket-detail-new h3 {
  font-size: 20px;
  font-weight: 700;
}

.gener-new {
  display: inline-block;
}
.gener-new ul li {
  float: left;
  list-style: none;
  font-size: 12px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dot-sb {
  height: 4px;
  width: 4px;
  background-color: #000000;
  display: inline-block;
  margin-right: 9px;
}
.final-ticket-detail-new {
  padding: 20px 20px 22px 0px;
}

.final-ticket-detail-new h5 {
  font-size: 14px;
  font-weight: 700;
}

.final-ticket-detail-new p {
  font-size: 14px;
  color: #000;
  margin-bottom: 4px;
}
.new-direction{
  font-size: 12px !important;
  font-weight: 700;
  text-decoration: underline;
}
.direction-new {
  margin-left: 5px;
}
.final-ticket-divider {
  background: #D6D6D6;
  width: 100%;
  height: 1.1px;
  margin: 20px 0px;
}
.date-new-ticket h6 {
  font-size: 11px;
  color: #808190;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.date-new-ticket p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}

.new-seat-info h6 {
  font-size: 11px;
  text-transform: uppercase;
  color: #808190;
  margin-bottom: 4px;
}

.seat-number-final p {
  font-size: 12px;
  margin-bottom: 0px;
}
.select-seat-number-final.new-ticket-box {
  margin-bottom: 0px !important;
}
.Ticket_summary-new h5 {
  font-size: 13px;
  color: #9FA1AC;
  font-weight: 700;
}

.Ticket_summary-new {
  padding: 6px 20px;
}

.food-list-new ul li {
  float: left;
  list-style: none;
  margin-right: 14px;
  font-size: 14px;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 14px;
  align-items: center;
}

.food-list-new span img {
  margin-right: 5px;
  width: 69%;
  margin-bottom: 3px;
}

.grand-total-price-new h6 {
  font-size: 11px;
  margin-bottom: 0px;
  color: #808190;
}

.grand-total-price-new p {
  font-size: 20px;
  font-weight: 400;
}
.food-list-new {
  display: inline-block;
}

.terms-ticket-cancel {
  box-shadow: 0px 2px 0px #DDD;
  background: #FFFDF5;
  border: 1px solid rgba(125, 125, 125, 0.50);
  margin: 20px 0px;
  border-radius: 8px;
  padding: 20px;
}

.now-ticket-new h3 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;
}

.refund-discount-new h5 {
  font-size: 14px;
  font-weight: 500;
}

.refund-discount-new p {
  font-size: 12px;
  margin-bottom: 10px;
}
.terms-use-ticket {
  box-shadow: 0px 2px 0px #DDD;
  background: #fff;
  border: 1px solid rgba(125, 125, 125, 0.50);
  border-radius: 8px;
  padding: 20px;
}

.terms-use-ticket h6 {
  font-size: 14px;
  font-weight: 700;
}

.terms-use-ticket ul li {
  font-size: 13px;
  margin-bottom: 20px;
}

.terms-use-ticket p {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.p-inputtext:enabled:focus{
  box-shadow: none !important;
  border-color: #EDE8E9 !important;
}
.p-inputtext:enabled:hover {
  border-color: #EDE8E9 !important;
}

.payment-feild .p-float-label input.p-filled ~ label{
  top:13px !important;
}

.saveauto{
  padding: 14px 19px;
    background: #fff;
    border-radius: 0px 0px 8px 8px;
}

.voucher-passport img {
  width: 100%;
}
.voucher h6 {
  font-size: 12px;
  text-transform: uppercase;
  color: #808190;
  font-weight: 400;
}
.redmee-auto-fil {
  background: #fff;
  padding: 5px;
  border: 1px solid #DCDCDC;
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 10px;
  /* height: 40px; */
}

.passport-voucher .custom-checkbox {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #000; /* Green border color */
  position: relative;
}

.passport-voucher .tick {
  content: "";
  position: absolute;
  left: 3px;
  top: -1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

/* Hide the default checkbox */
.passport-voucher input[type="checkbox"] {
  display: none;
}

/* Show the tick when the checkbox is checked */
.passport-voucher input[type="checkbox"]:checked + .custom-checkbox .tick {
  display: block;
}

/* Style the custom checkbox when checked */
.passport-voucher input[type="checkbox"]:checked + .custom-checkbox {
  background-color: #1EA234;
  border: 2px solid #1EA234; /* Darker green when checked */
}
.passport-voucher {
  display: flex;
  align-items: center;
}

.passport-redmee p {
  font-size: 10px;
  margin-bottom: 0px;
  margin-left: 6px;
  margin-top: -5px;
  font-weight: 600;
  display: inline-block;
  padding: 4px;
}

.offer-box-save {
  background: #F8F8F8;
  border-radius: 8px;
  padding: 10px 12px 10px 12px;
  margin-bottom: 6px;
  flex: 0 0 auto;
  margin-right:6px;
}

.passport-redmee h5 {
  font-size: 10px;
  margin-left: 15px;
  margin-bottom: 2px;
}

.passport-redmee h6 {
  font-size: 14px;
  color: #908E8E;
  margin-left: 15px;
}
.saved-card h6 {
  text-align: center;
  font-weight: 500;
  color: #7A7A7A;
  font-size: 12px;
  margin-top: 15px;
}
.all-card-saved {
  background: #fff;
  border: 1px solid #DDD;
  border-radius: 8px;
  padding: 14px 19px;
}
.card-show {
  display: flex;
  align-items: center;
}
.Saved-card-box-offer-view{
  background: #F1F1F1;
  padding: 8px 10px;
  border-radius: 8px 8px 0px 0px;
}
.Saved-card-box {
  background: #F1F1F1;
  padding: 8px 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.Card-hoder h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}

.Card-hoder p {
  font-size: 14px;
  margin-bottom: 0px;
}
.card-type img {
  background: #fff;
  padding: 13px;
  border-radius: 6px;
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin-right: 12px;
}
.offer-buy-ticket {
  background: #007D23;
  padding: 10px 10px 10px 10px;
  margin-bottom: 37px;
  border-radius: 0px 0px 8px 8px;
}
.offer-buy-ticket p {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  margin: 0;
}
.veg-price p {
  font-size: 12px;
  font-weight: 500;
  text-align: right;
}


/* MOBILE PAYMENT CSS */
.offer-explore {
  padding: 17px 20px 0px 20px;
}

.bank-offer-box {
  /* padding: 10px 20px; */
}

.bank-offer {
  background: #fff;
  border: 1px solid #E8E8E8;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 1px 0px #E8E8E8;
  margin-bottom: 10px;
}

.bank-offer-details-side h5 {
  font-size: 13px;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Poppins';
}

.bank-offer-details-side h6 {
  font-size: 12px;
  color: #000;
  font-weight: 400;
  font-family: 'Poppins';
}

.bank-offer-details-side p {
  color: #7A7A7A;
  font-size: 12px;
  margin-bottom: 0px;
  text-decoration: underline;
}

.food-beverages {
  width: 99%;
}

.exolpre-input {
  border: 1px solid #DDD !important;
  height: 46px;
  padding-left: 15px !important;
}

.offer-card-show {
  border-radius: 30px;
    border: 1px solid #EDE8E9;
    margin-bottom: 20px;
    padding: 9px 3px 14px 2px;
    background: #fff;
    display: inline-block;
}

.card-view-offer {
  padding: 20px 27px;
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #000;
  cursor: pointer;
}

.card-active {
  /* border: 1px solid #FFCB05; */
  border-radius: 30px;
  padding: 11px 27px;
  /* background: #FFF0D6; */
}
.avail-voucher {
  display: flex;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}

.avail-voucher::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

.avail-voucher::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.avail-voucher::-webkit-scrollbar-thumb {
  background: #888; 
}

.voucher-redmeeauto {
  margin-right: 10px;
  width: 33.33%;
  flex: 0 0 auto;
  white-space: nowrap;
}
.passport-blur {
  filter: blur(3px);
}
.auto-voucher {
  display: flex;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}
.auto-voucher::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
.auto-voucher::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.auto-voucher::-webkit-scrollbar-thumb {
  background: #888; 
}

.visa-type {
  position: relative;
}

.type-card-show-payment {
  position: absolute;
  top: 14px;
  right: 33px;
}

.check-payment-inox .p-checkbox .p-checkbox-box.p-highlight{
  border-color:#234B9E !important;
  background:#234B9E !important;
}
.check-payment-inox .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #234B9E !important;
  background: #234B9E!important;
  color: #ffffff;
}

.check-payment-inox  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #234B9E !important;
}

.check-payment-inox  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #234B9E !important;
}

.check-payment-pvr .p-checkbox .p-checkbox-box.p-highlight{
  border-color:#FFCB05;
  background:#FFCB05;
}
.check-payment-pvr .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #FFCB05;
  background: #FFCB05;
  color: #ffffff;
}
.check-payment-pvr  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #FFCB05;
}
.check-payment-pvr .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #FFCB05;
}
/* MOBILE PAYMENT CSS */

.offer-ml {
  margin-right: 1rem!important;
  margin-left: 1.2rem!important;
}


/* New Bank Offer design */
.bank-offer-new {
  margin-bottom: 14px;
}
.bank-offer-new  .now-showing-filter-inox .p-tabview .p-tabview-nav{
  border: 1px solid #dee2e6;
}
.all-bank-list-show {
  display: flex;
  flex: 0 auto;
  overflow-x: scroll;
  margin-bottom: 20px;
  margin-top: 17px;
  /* padding: 0px 14px; */
}
.all-bank-list-show::-webkit-scrollbar {
  height: 3px;
}

.all-bank-list-show::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
.all-bank-list-show::-webkit-scrollbar-thumb {
  background: #888; 
}
.Bank-name-inox{
  border: 1px solid #234B9E;
  background: #DCE2EF;
  border-radius: 30px;
  color: #7A7A7A;
  margin-right: 8px;
  cursor: pointer;
  padding: 4px 17px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  white-space: nowrap;

}

.Bank-name-pvr {
  border: 1px solid #FFCB05;
  background: #FFF0D6;
  border-radius: 30px;
  color: #7A7A7A;
  margin-right: 8px;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 4px 17px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  white-space: nowrap;
}

.Bank-name-pvr p {
  font-size: 13px;
  margin: 0;
  font-weight: 500;
  color: #000;
  font-weight: 500;
  cursor: pointer;
}

.Bank-name-inox p {
  font-size: 13px;
  margin: 0;
  font-weight: 500;
  color: #000;
  font-weight: 500;
  cursor: pointer;
}
.unlock-passport p {
  font-size: 10px;
  margin-bottom: 0;
  font-weight: 600;
  color: #000;
  display: inline-block;
  padding: 5px;
}
.Bank-name {
  border: 1px solid #EDE8E9;
  background: #fff;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7A7A7A;
  margin-right: 8px;
  padding: 4px 17px;
  height: 30px;
  flex : 0 0 auto;
  cursor: pointer;
  margin-bottom: 10px;
  white-space: nowrap;

}

.Bank-name p {
  font-size: 13px;
  margin: 0;
  font-weight: 500;
  cursor: pointer;
}
.explore-offer-new {
  padding: 5px 16px;
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 600;
  border: none;
  border-radius: 5px;
}
.Apply-offer-new {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bank-offer-middle {
  align-items: center;
}
.bank-offer-details-side h5 {
  font-family: poppins;
  font-size: 11px;
  color: #000;
}
/* New Bank Offer design */

@media (max-width: 575.98px) {
  .verfiy-paytm {
    width: 88% !important;
}
}


/* sucharge */
.upgrade-your h3 {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}

.please-review-pvr {
  /* background: #DCE2EF; */
  padding: 8px 14px;
  margin-bottom: 8px;
  border-radius: 6px;
}


.please-review-pvr p {
  font-size: 12px;
  margin-bottom: 0px;
  color: #000;
}

.format-passport-view {
  background: #F9F9F9;
  display: flex;
  justify-content: space-between;
  padding: 14px;
  margin-bottom: 8px;
  border-radius: 6px;
}

.fromat-popup-view h3 {
  font-size: 14px;
  color: #000;
  margin-bottom: 0px;
}

.fromat-popup-price p {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0px;
}
.preedeemm {
  color: green !important;
}
.format-view-gap-passport {
  margin-left: 8px;
  font-weight: 600;
}

.surcharge-proceed{
  padding: 8px 61px;
  border: none;
  font-family: poppins;
  margin-right: 14px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
}

.surcharge-proceed:last-child{
  margin-right: 0px;
}

.seat-show-recliner {
  margin-left: 10px;
  font-weight: 600;
}

.seat-show-recliner img {
  margin-right: 7px;
}

.voucher-passport-new {
  background: #6F1612;
  padding: 10px 8px 10px 8px;
  border-radius: 6px 6px 0px 0px;
}
.voucher-passport-reedeem{
  background: #6c757d !important;
}
.voucher-passport-new img {
  margin-bottom: 10px;
}
.passport-card-number h6 {
  color: #B78A88;
  font-size: 11px;
  margin-bottom: 4px;
}

.passport-card-number h4 {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
}
/* surcharge */

.lock-icon img {
  margin-bottom: 4px;
}
.otp-number {
  text-align: center;
}
.passport-proceed {
  border: none;
  width: 100%;
  padding: 6px;
  border-radius: 6px;
  font-family: 'Poppins';
}

.passport-success-msg{
  color: #007D23;
}

.passport-error-msg{
  color: #C33737;
}
.discount-price {
  color: #1A8C2D;
  font-weight: 700;
  text-decoration: line-through;
  margin-right: 10px;
}
.payment-privilege {
  padding: 24px 18px;
  background: #fff;
  border-radius: 0px 0px 8px 8px;
}
.privilege-head h4 {
  font-size: 15px;
  color: #808190;
  margin-bottom: 20px;
}
.privilege-voucher-show-details {
  position: relative;
  padding: 10px;
  border-radius: 6px 6px 0px 0px;
  background-image: linear-gradient(#1c1c1c,#272727);
  overflow: hidden;
  letter-spacing: 0;
  z-index: 0;
}
.privilege-voucher-show-details::after{
    position: absolute;
    inset: 0;
    background: url('../../assets/payment/PP+PVRINOX.png') no-repeat right center/contain;
    z-index: -1;
    content: "";
}

.vail-date-privi {
  display: flex;
  align-items: flex-end;
}

.privilege-qr {
  width: 25%;
  padding-right: 8px;
}

.privilege-id {
  width: 75%;
}
.privilege-id h6 {
  font-size: 12px;
  color: #fff;
  margin-bottom: 3px;
}

.privilege-id p {
  font-size: 9px;
  color: #fff;
  margin-bottom: 0px;
}
.privilege-qr h5 {
  margin-bottom: 4px;
  font-size: 14px;
  color: #fff;
}

.payment-fnb{
  margin-right: 10px;
  width: 33.33%;
  flex: 0 0 auto;
  white-space: nowrap;
}

.payment-food-show{
  background-image: linear-gradient(#1c1c1c, #272727);
  border-radius: 6px 6px 0px 0px;
  letter-spacing: 0;
  overflow: hidden;
  padding: 8px 8px 8px 8px;
  position: relative;
  z-index: 0;
  height: 65%;
}
.payment-food-show::after{position: absolute;
    inset: 0;
    background: url(../../assets/payment/popcronbucket.png) no-repeat right center/contain;
    z-index: -1;
    content: "";}
    .payment-food-show h6 {
      font-size: 12px;
      color: #fff;
      margin: 2px;
  }
  
  .payment-food-show h3 {
      font-size: 14px;
      color: #828282;
      margin: 1px;
  }
  
  .payment-food-show h5 {
      font-size: 14px;
      color: #fff;
  }
  .payment-food-show span {
    font-size: 12px;
    color: #fff;
}

  .net-terms {
    font-size: 16px;
    color: #000;
    font-weight: 600;
}
.net-term{
  font-size: 14px;
    color: #000;
}